import { AsyncRoute } from '@app/utils/routing/types'

const Parent = () => import('./Parent').then(module => module.Parent)
const Sitter = () => import('./Sitter').then(module => module.Sitter)

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/p/:token',
    event_id: 'parent_profile',
    event_params: { profile_user_id: 'token' },
    component: Parent,
  },
  {
    path: '/s/:sitter_token',
    event_id: 'sitter_profile',
    event_params: { profile_user_id: 'sitter_token' },
    component: Sitter,
  },
  {
    path: '/s/:sitter_token/:description',
    event_id: 'sitter_profile',
    event_params: { profile_user_id: 'sitter_token' },
    component: Sitter,
  },
  {
    path: '/:sitter_token',
    event_id: 'sitter_profile',
    event_params: { profile_user_id: 'sitter_token' },
    component: Sitter,
  },
  {
    path: '/:sitter_token/:description',
    event_id: 'sitter_profile',
    event_params: { profile_user_id: 'sitter_token' },
    component: Sitter,
  },
]

export default routes
