export function wrapError(e: Error, previousError: unknown): Error {
  e.cause = previousError
  return e
}

export function wrapPromise<P>(p: Promise<P>, e: Error): Promise<P> {
  return p.catch(err => {
    throw wrapError(e, err)
  })
}
