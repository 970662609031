import { mergeAbortSignals } from '@app/utils/mergeAbortSignals'

import { getContext, withChildContext } from '@app/packages/actionContext/actions'

import { createThunk, ThunkAction } from '@app/store/thunk'

import { AbortSignalsManager } from './AbortSignalsManager'

export function withAbortSignal<R>(abortSignal: AbortSignal | undefined, action: ThunkAction<R>) {
  return withChildContext(
    createThunk(async dispatch => {
      const manager = dispatch(getAbortSignalsManager())
      const sub = abortSignal ? manager.add(abortSignal) : null
      try {
        return await dispatch(action)
      } finally {
        sub?.()
      }
    }) as typeof action
  )
}

export function getAbortSignalsManager() {
  return createThunk(dispatch => {
    const context = dispatch(getContext())
    if (!context.get(AbortSignalsManager)) {
      context.set(new AbortSignalsManager())
    }
    return context.get(AbortSignalsManager)!
  })
}

export function getAbortSignal() {
  return createThunk(dispatch => {
    const signals: AbortSignal[] = []
    let context = dispatch(getContext())
    while (true) {
      const manager = context.get(AbortSignalsManager)
      if (manager) {
        signals.push(...manager.signals.map(s => s.value))
      }
      if (!context.parent) break
      context = context.parent
    }
    if (!signals.length) return undefined
    return mergeAbortSignals(...signals)
  })
}
