import { ApiAnnouncement, ApiAnnouncementResponse, ApiAvatar, ApiLocation, ApiSavedAnnouncementSearch, ApiUser } from '@app/constants/ApiTypes/entities'
import { SavedAnnouncementSearchesByIdAnnouncemenetsMeta } from '@app/constants/ApiTypes/misc'
import {
  SavedAnnouncementSearchesByIdAnnouncemenetsQuery,
  SavedAnnouncementSearchPostRequest,
  SavedAnnouncementSearchPutRequest,
} from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const postSavedAnnouncementSearchesDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postSavedAnnouncementSearches')
  .setShape<{ data: ApiSavedAnnouncementSearch }, { request: SavedAnnouncementSearchPostRequest }>()

export const getSavedAnnouncementSearchesDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getSavedAnnouncementSearches')
  .setShape<{ data: ApiSavedAnnouncementSearch[] }>()

export const getSavedAnnouncementSearchesByIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getSavedAnnouncementSearchesById')
  .setShape<{ data: ApiSavedAnnouncementSearch }, { id: string }>()

export const putSavedAnnouncementSearchesByIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putSavedAnnouncementSearchesById')
  .setShape<unknown, { id: string; request: SavedAnnouncementSearchPutRequest }>()

export const deleteSavedAnnouncementSearchesByIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('deleteSavedAnnouncementSearches')
  .setShape<unknown, { id: string }>()

export const getSavedAnnouncementSearchesByIdAnnouncementsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getSavedAnnouncementSearchesByIdAnnouncements')
  .setShape<
    {
      data: ApiAnnouncement[]
      included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[]
      meta: SavedAnnouncementSearchesByIdAnnouncemenetsMeta
    },
    { id: string; query: SavedAnnouncementSearchesByIdAnnouncemenetsQuery }
  >()
