import { createSelector } from 'reselect'

import { ApiContact, ApiRegion } from '@app/constants/ApiTypes/entities'

import { createSortHandler } from '@app/utils/createSortHandler'

import { StoreState } from '@app/store/store'

import { regionsModelsSelector } from './regions'

export const contactsModelsSelector = (state: StoreState) => state.contacts.models
export const contactsListSelector = createSelector([contactsModelsSelector, regionsModelsSelector], (models, regions) => {
  const contacts = Object.values(models)
    .map(c => {
      const regionId = c.relationships.region.data?.id
      const region = regionId ? regions[regionId] : null
      if (regionId && !region) throw new Error("Can't find region")

      return {
        id: c.id,
        attributes: c.attributes,
        region,
      }
    })
    .sort(createSortHandler(a => [a.region?.id ? getRegionWeight(a.region) : -1, a.region?.id ? -parseFloat(a.region.id) : 1, -parseFloat(a.id)]))

  return contacts
})

export const contactsSchoolsSelector = createSelector([contactsListSelector], contacts =>
  contacts.reduce<Partial<Record<string, ApiContact['attributes']>>>((acc, c) => {
    if (c.attributes.department === 'school' && !!c.region) {
      acc[c.region.id] = c.attributes
    }
    return acc
  }, {})
)

export const contactsAcademySelector = createSelector([contactsListSelector], contacts => contacts.find(c => c.attributes.department === 'academy'))

function getRegionWeight(region: ApiRegion) {
  return REGION_WEIGHTS.indexOf(region.attributes.type) + 1
}

const REGION_WEIGHTS = ['world', 'country', 'city']
