import {} from '@app/utils/normalizer'

import { ApiLocation } from '@app/constants/ApiTypes/entities'

import { apiNormalize } from '@app/utils/apiNormalizer'
import { mergeMaps } from '@app/utils/mergeMaps'

import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiLocation
}>({}, builder => {
  builder.setDefaultHandler((state, action) => {
    const { locations } = apiNormalize(action)
    if (!locations) return state

    return mergeMaps(state, locations)
  })
})
