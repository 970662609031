import * as valita from '@badrap/valita'

import { IMPORT_MAP } from '@app/importMap'

import { Point } from '@app/packages/geo/Point'

import type { LabelExtractor } from './addressTools'

type LocationLatLonArray = [number, number]

/**
 * @deprecated use "@app/packages/geo/Address" instead
 */
export class Address {
  private __latitude: number
  private __longitude: number
  private __label?: string
  private __kind?: string
  /** South west point*/
  private __lowerCorner?: LocationLatLonArray
  /** North east point*/
  private __upperCorner?: LocationLatLonArray

  constructor({
    latitude,
    longitude,
    label,
    kind,
    lowerCorner,
    upperCorner,
  }: {
    latitude: number
    longitude: number
    label?: string
    kind?: string
    lowerCorner?: LocationLatLonArray
    upperCorner?: LocationLatLonArray
  }) {
    this.__latitude = latitude
    this.__longitude = longitude
    this.__label = label
    this.__kind = kind
    this.__lowerCorner = lowerCorner
    this.__upperCorner = upperCorner
  }

  get label() {
    return this.__label || [this.__latitude, this.__longitude].join(', ')
  }

  get kind() {
    return this.__kind
  }

  get rawLabel() {
    return this.__label || ''
  }

  get latitude() {
    return this.__latitude
  }

  get longitude() {
    return this.__longitude
  }

  get coords() {
    return [this.__latitude, this.__longitude] as LocationLatLonArray
  }

  get bounds(): ymaps.Bounds | null {
    return this.lowerCorner && this.upperCorner ? [this.lowerCorner, this.upperCorner] : null
  }

  get lowerCorner() {
    return this.__lowerCorner
  }

  get upperCorner() {
    return this.__upperCorner
  }

  isHouse() {
    return !!this.kind && ['house', 'entrance'].includes(this.kind)
  }

  hasLabel() {
    return !!this.__label
  }

  valueOf() {
    return {
      latitude: this.__latitude,
      longitude: this.__longitude,
      label: this.__label,
      kind: this.__kind,
      lowerCorner: this.__lowerCorner,
      upperCorner: this.__upperCorner,
    }
  }

  /** converts to type that is comes from api */
  toLocation(rawLabel = false) {
    return {
      latitude: this.__latitude,
      longitude: this.__longitude,
      address: rawLabel ? this.rawLabel : this.label,
      kind: this.kind,
    }
  }

  toString() {
    return `[Address: lat ${this.__latitude}, lon ${this.__longitude}${this.__label ? ` label ${this.__label}` : ''}]`
  }

  toJSON() {
    return {
      latitude: this.__latitude,
      longitude: this.__longitude,
      address: this.rawLabel,
      kind: this.kind,
      lowerCorner: this.lowerCorner,
      upperCorner: this.upperCorner,
    }
  }

  static fromLocation({
    latitude,
    longitude,
    address,
    kind,
    lowerCorner,
    upperCorner,
  }: {
    latitude: number
    longitude: number
    address?: string
    kind?: string
    lowerCorner?: LocationLatLonArray
    upperCorner?: LocationLatLonArray
  }) {
    // eslint-disable-next-line deprecation/deprecation
    return new Address({
      latitude,
      longitude,
      label: address,
      kind,
      lowerCorner,
      upperCorner,
    })
  }

  static fromJSON({
    latitude,
    longitude,
    address,
    kind,
    lowerCorner,
    upperCorner,
  }: {
    latitude: number
    longitude: number
    address?: string
    kind?: string
    lowerCorner?: LocationLatLonArray
    upperCorner?: LocationLatLonArray
  }) {
    // eslint-disable-next-line deprecation/deprecation
    return new Address({
      latitude,
      longitude,
      label: address,
      kind,
      lowerCorner,
      upperCorner,
    })
  }

  static async fromLabel(label: string, extractLabel?: LabelExtractor) {
    const { getGeocodeData } = await IMPORT_MAP.utils.addressTools()
    const address = await getGeocodeData(label, { extractLabel })
    if (!address) return null
    // eslint-disable-next-line deprecation/deprecation
    return new Address({
      label: address.label,
      kind: address.kind,
      latitude: address.location.lat,
      longitude: address.location.lon,
      lowerCorner: [address.bounds.bottomLeft.lat, address.bounds.bottomLeft.lon],
      upperCorner: [address.bounds.topRight.lat, address.bounds.topRight.lon],
    })
  }

  static async fromPoint(point: Point, extractLabel?: LabelExtractor) {
    const { getGeocodeData } = await IMPORT_MAP.utils.addressTools()
    const address = await getGeocodeData(point, { extractLabel })
    if (!address) return null
    // eslint-disable-next-line deprecation/deprecation
    return new Address({
      label: address.label,
      kind: address.kind,
      latitude: address.location.lat,
      longitude: address.location.lon,
      lowerCorner: [address.bounds.bottomLeft.lat, address.bounds.bottomLeft.lon],
      upperCorner: [address.bounds.topRight.lat, address.bounds.topRight.lon],
    })
  }

  static fromUnknown(data: unknown) {
    const result = RT_AddressShape.try(data)
    if (!result.ok) return null
    // eslint-disable-next-line deprecation/deprecation
    return new Address({ ...result.value, label: result.value.address })
  }
}

const RT_Coord = valita.tuple([valita.number(), valita.number()])

const RT_AddressShape = valita.object({
  latitude: valita.number(),
  longitude: valita.number(),
  address: valita
    .string()
    .map(v => v.trim())
    .assert(v => v.length >= 1)
    .optional(),
  kind: valita
    .string()
    .assert(v => v.length >= 1)
    .optional(),
  lowerCorner: RT_Coord.optional(),
  upperCorner: RT_Coord.optional(),
})
