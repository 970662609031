import { getUsersMeDescriptor } from '@app/store/actions/api/users.descriptors'
import {
  addAvatar,
  appendAvatarIds,
  deleteAvatarDescriptor,
  postAuthPhoneVerifyDescriptor,
  postEmailCodeVerificationDescriptor,
  postEmailTokenVerificationDescriptor,
  postTOSAcceptanceDescriptor,
} from '@app/store/actions/profile.descriptors'
import { socialLoginActionDescriptor } from '@app/store/actions/session.descriptors'
import { postAuthTelegramDescriptor } from '@app/store/actions/telegram.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[]>([], builder => {
  builder.addCases(
    [
      getUsersMeDescriptor.shapes.fulfilled,
      socialLoginActionDescriptor.shapes.fulfilled,
      postAuthTelegramDescriptor.shapes.fulfilled,
      postEmailCodeVerificationDescriptor.shapes.fulfilled,
      postEmailTokenVerificationDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      if (!action.payload.included) return state
      const avatars = action.payload.included.filter(i => i.type === 'avatars')
      return avatars.map(a => a.id)
    }
  )

  builder.addCase(deleteAvatarDescriptor.shapes.pending, (state, action) => {
    return state.filter(x => x !== action.meta.avatarId)
  })

  builder.addCase(appendAvatarIds.shape, (state, action) => state.concat(action.payload))

  builder.addCase(addAvatar.shape, (state, action) => {
    const index = state.indexOf(action.payload.avatarId)
    if (index === -1) return state
    return [...state.slice(0, index), action.payload.avatar.id, ...state.slice(index + 1)]
  })

  builder.addCases([postTOSAcceptanceDescriptor.shapes.fulfilled, postAuthPhoneVerifyDescriptor.shapes.fulfilled], (state, action) => {
    if (
      !action.payload.data ||
      !action.payload.data.relationships ||
      !action.payload.data.relationships.avatars ||
      !action.payload.data.relationships.avatars.data
    )
      return state
    if (action.payload.data.relationships.avatars.data.length === 0) return []
    return action.payload.data.relationships.avatars.data.map(item => item.id)
  })
})
