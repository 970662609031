import { defineMessages } from 'react-intl'

export const MISC_MESSAGES = defineMessages({
  kids: `{kids, plural,
      one {# ребенок}
      few {# ребенка}
      other {# детей}}`,
  kids_undefined: 'Неважно',
  babies: `{babies, plural,
      one {# младенец}
      few {# младенца}
      other {# младенцев}}`,
  hours: `{hour, plural,
      one {# час}
      few {# часа}
      other {# часов}}`,
  hoursWithMinutes: `{hour, plural,
      =0 {}
      one {# час }
      few {# часа }
      other {# часов }}{minutes, plural,
      =0 {}
      one {# минута}
      few {# минуты}
      other {# минут}}`,
  hoursWithMinutesShort: `{hour, plural,
      =0 {}
      other {# ч }}{minutes, plural,
      =0 {}
      other {# м}}`,
  km: '{distance}км',
  m: '{distance, number, distance}м',
  age: `{age, plural,
        one {# год}
        few {# года}
        other {# лет}}`,
  accurate_age: `{lessThanMonth, select,
      true {меньше месяца}
      other {{hasYears, select,
        true
          {{years, plural,
            one {# год}
            few {# года}
            other {# лет}
          }}
        other {}
        }{hasMonths, select,
          true
          {{hasYears, select,
            true {, }
            other {}
          }{months, plural,
            one {# месяц}
            few {# месяца}
            other {# месяцев}
          }}
        other {}}}}`,
  opinions: `{opinions, plural,
      =0 {нет отзывов}
      one {# отзыв}
      few {# отзыва}
      other {# отзывов}}`,
  finishSchool: `{gender, select,
      m {прошел школу Kidsout}
      f {прошла школу Kidsout}
      other {прошел(а) школу Kidsout}}`,
  supervise_chat_disabled: 'Чаты отключены. Просмотреть сообщения можно в админке.',
  error_occured: 'Произошла ошибка',
})
