import isNil from 'lodash/isNil'
import omit from 'lodash/omit'

import { IMPORT_MAP } from '@app/importMap'

import { ApiAvatar, ApiSocialIdentity } from '@app/constants/ApiTypes/entities'
import { PhoneVerificationMethod } from '@app/constants/ApiTypes/misc'
import { ApiDeleteFavoritesUserQuery, ApiPostFavoritesUserRequest, PhoneVerificationRequest, ProfileUpdateRequest } from '@app/constants/ApiTypes/requests'

import { AnalyticsEvent } from '@app/services/AnalyticsEvent'

import * as api from '@app/utils/api'
import { toE164 } from '@app/utils/phoneTools/toE164'
import { RAIIGuard } from '@app/utils/RAIIGuard'
import { placeCookie, regionCookie } from '@app/utils/routing/region'
import { urlEscaped } from '@app/utils/urlEscaped'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'
import { asNetworkError } from '@app/store/apiMiddleware/errors'
import { ApiActionPromise } from '@app/store/apiMiddleware/types'
import { createTypelessApiAction } from '@app/store/apiMiddleware/utils'
import { profileUserSelector } from '@app/store/selectors/profile'
import { defaultRegionModelSelector, regionsModelsSelector } from '@app/store/selectors/regions'
import { createThunk } from '@app/store/thunk'
import { ProfileUpdateState } from '@app/store/types/profile'

import {
  addToFavoritesDescriptor,
  deleteAvatarDescriptor,
  deleteFromFavoritesDescriptor,
  deleteIdentitiesByIdDescriptor,
  emailConfirmDescriptor,
  getFavoritesDescriptor,
  getIdentitiesDescriptor,
  getPhoneDeliveryMethodsDescriptor,
  phoneChangeConfirmDescriptor,
  postAuthPhoneRequestCodeDescriptor,
  postAuthPhoneVerifyDescriptor,
  postEmailCodeVerificationDescriptor,
  postEmailTokenVerificationDescriptor,
  postIdentitiesDescriptor,
  postTOSAcceptanceDescriptor,
  setProfilePlaceActionDescriptor,
  updateProfileActionDescriptor,
} from './profile.descriptors'
import { getPlaceById, getPlaceByRegion, PlaceResponse } from './region'
import { setSession } from './session'

export const updateProfileAction = new ApiActionBuilder(updateProfileActionDescriptor)
  .setInit((request: ProfileUpdateRequest) => ({
    endpoint: state => api.path(urlEscaped`/api/v2/users/${state.profile.user!.id}`)(state),
    method: 'PUT',
    headers: api.headers(),
    body: JSON.stringify(request),
  }))
  .build()

export function updateProfile(data: ProfileUpdateState) {
  return createThunk(async (dispatch, getState) => {
    try {
      dispatch<typeof updateProfileAction.descriptor.shapes.pending>({
        type: updateProfileAction.descriptor.shapes.pending.type,
        meta: undefined,
      })
      const patch = convertProfileUpdateStateToProfilePatch(data)
      const response = await dispatch(updateProfileAction(patch))

      if (response && !response.error) {
        await dispatch(
          setSession({
            access_token: getState().session.access_token || '',
          })
        )
      }

      return response
    } catch (e) {
      dispatch<typeof updateProfileAction.descriptor.shapes.rejected>({
        type: updateProfileAction.descriptor.shapes.rejected.type,
        meta: undefined,
        error: true,
        payload: e as Error,
      })
    }
  })
}

export const getPhoneDeliveryMethods = new ApiActionBuilder(getPhoneDeliveryMethodsDescriptor)
  .setInit((phone: string) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/auth/phone/delivery_methods', { phone: toE164(phone) }),
    headers: api.headers(),
  }))
  .build()

export const postAuthPhoneRequestCode = new ApiActionBuilder(postAuthPhoneRequestCodeDescriptor)
  .setInit((phone: string, purpose: 'auth' | 'phone_change', delivery: PhoneVerificationMethod) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/auth/phone/request_code'),
    headers: api.headers(),
    body: JSON.stringify({ phone, purpose, delivery }),
    meta: { phone, delivery },
    after(resp) {
      if (resp?.error) {
        new AnalyticsEvent('phone_verification_fail', { purpose, delivery }).sendAmplitude()
      } else {
        new AnalyticsEvent('phone_verification', { purpose, delivery }).sendAmplitude()
      }
    },
  }))
  .build()

export const postAuthPhoneVerify = new ApiActionBuilder(postAuthPhoneVerifyDescriptor)
  .setInit((data: PhoneVerificationRequest) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/auth/phone/verify'),
    headers: api.headers(),
    body: JSON.stringify(data),
    meta: { data },
    before() {
      new AnalyticsEvent('phone_confirmation', {}).sendAmplitude()
    },
    async after(resp, dispatch, _getState) {
      if (resp) {
        if (!resp.error) {
          new AnalyticsEvent('phone_confirmation_success', {}).sendAmplitude()
          await dispatch(
            setSession({
              access_token: resp.payload.data.meta.access_token,
            })
          )
        } else {
          new AnalyticsEvent('phone_confirmation_fail', {}).sendAmplitude()
        }
      }
    },
  }))
  .build()

export const phoneChangeConfirm = new ApiActionBuilder(phoneChangeConfirmDescriptor)
  .setInit((data: { phone: string; code: string }) => ({
    method: 'PUT',
    endpoint: state => api.path(urlEscaped`/api/v2/users/${state.profile.user!.id}/phone`)(state),
    headers: api.headers(),
    body: JSON.stringify(data),
    meta: { data },
  }))
  .build()

export const emailConfirm = new ApiActionBuilder(emailConfirmDescriptor)
  .setInit((email: string, cause?: string) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/auth/email/request'),
    headers: api.headers(),
    body: JSON.stringify({ email, cause }),
    meta: { email, cause },
  }))
  .build()

export const postEmailTokenVerification = new ApiActionBuilder(postEmailTokenVerificationDescriptor)
  .setInit((token: string) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/auth/email/token_verification'),
    headers: api.headers(),
    body: JSON.stringify({ token }),
    meta: { token },
  }))
  .build()

export const postEmailCodeVerification = new ApiActionBuilder(postEmailCodeVerificationDescriptor)
  .setInit(({ email, code }: { email: string; code: string }) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/auth/email/code_verification'),
    headers: api.headers(),
    body: JSON.stringify({ email, code }),
    meta: { email, code },
    before() {
      new AnalyticsEvent('email_code_verification', {}).sendAmplitude()
    },
    async after(resp, dispatch, _getState) {
      if (resp && !resp.error) {
        if (resp.error) {
          new AnalyticsEvent('email_code_verification_fail', {}).sendAmplitude()
        } else {
          new AnalyticsEvent('email_code_verification_success', {}).sendAmplitude()
          await dispatch(setSession({ access_token: resp.payload.data.meta.access_token }))
        }
      }
    },
  }))
  .build()

export const deleteAvatar = new ApiActionBuilder(deleteAvatarDescriptor)
  .setInit((avatarId: string) => ({
    method: 'DELETE',
    endpoint: api.path(urlEscaped`/api/v2/avatars/${avatarId}`),
    headers: api.headers(),
    meta: { avatarId },
  }))
  .build()

export const uploadAvatar = (file: File, onProgress?: (event: { percent?: number }) => void) => {
  return createThunk(async (dispatch, getState) => {
    let invertedProgress = 1

    const interval = setInterval(() => {
      invertedProgress = invertedProgress - invertedProgress * 0.3
      onProgress?.({ percent: (1 - invertedProgress) * 100 })
    }, 500)
    using guard = new RAIIGuard(val => {
      if (!val) clearInterval(interval)
    })
    guard.guard()

    const formData = new FormData()
    formData.append('file', file)

    const resp = await dispatch(
      createTypelessApiAction<{ data: ApiAvatar }>({
        method: 'POST',
        endpoint: api.path('/api/v2/avatars'),
        headers: omit(api.headers()(getState()), 'content-type'),
        body: formData,
      })
    )

    return resp
  })
}

export const postTOSAcceptance = new ApiActionBuilder(postTOSAcceptanceDescriptor)
  .setInit(() => ({
    method: 'POST',
    endpoint: api.path('/api/v2/tos/acceptance'),
    headers: api.headers(),
  }))
  .build()

export const getFavorites = new ApiActionBuilder(getFavoritesDescriptor)
  .setInit((page: number, per_page: number = 10) => {
    const query = { page, per_page }
    return {
      method: 'GET',
      endpoint: api.path('/api/v2/favorites', query),
      headers: api.headers(),
      bailout: ({ favorites }) => (favorites.meta.loading ? true : page === 1 ? favorites.meta.fetched : favorites.meta.page >= favorites.meta.total_pages),
      meta: { query },
    }
  })
  .build()

export function fetchFavorites(per_page: number) {
  return getFavorites(1, per_page)
}

export function fetchNextFavorites() {
  return createThunk((dispatch, getState) => {
    const { meta } = getState().favorites
    return dispatch(getFavorites((meta.page || 0) + 1, meta.per_page || 10))
  })
}

export const addToFavorites = new ApiActionBuilder(addToFavoritesDescriptor)
  .setInit((request: ApiPostFavoritesUserRequest) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/favorites'),
    headers: api.headers(),
    body: JSON.stringify(request),
    meta: request,
  }))
  .build()

export const deleteFromFavorites = new ApiActionBuilder(deleteFromFavoritesDescriptor)
  .setInit((query: ApiDeleteFavoritesUserQuery) => ({
    method: 'DELETE',
    endpoint: api.path('/api/v2/favorites', query),
    headers: api.headers(),
    meta: query,
  }))
  .build()

export const getIdentities = new ApiActionBuilder(getIdentitiesDescriptor)
  .setInit((force: boolean) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/identities'),
    headers: api.headers(),
    bailout: ({ profile, session }) => {
      if (!session.access_token || !profile.user || profile.user.account_type === 'visitor') return true
      if (force) return false
      return profile.socialIdentities.fetched
    },
  }))
  .build()

export const postIdentities = new ApiActionBuilder(postIdentitiesDescriptor)
  .setInit((provider: ApiSocialIdentity['attributes']['provider'], token: string) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/identities'),
    headers: api.headers(),
    body: JSON.stringify({ provider, token }),
    meta: { provider, token },
  }))
  .build()

export const deleteIdentitiesById = new ApiActionBuilder(deleteIdentitiesByIdDescriptor)
  .setInit((id: string | number) => ({
    method: 'DELETE',
    endpoint: api.path(urlEscaped`/api/v2/identities/${id}`),
    headers: api.headers(),
    meta: { id: `${id}` },
  }))
  .build()

export const setProfilePlaceAction = new ApiActionBuilder(setProfilePlaceActionDescriptor)
  .setInit((meta: { cause: 'change' | 'restore' }) => ({
    method: 'GET',
    endpoint: '',
    headers: api.headers(),
    meta,
  }))
  .build()

export function setPlace(payload: PlaceResponse) {
  return createThunk((dispatch, _getState, { abort, cookies }) => {
    abort.clearAbortController(PLACE_ABORT_CONTROLLER_LABEL)
    placeCookie.set(cookies, payload.data.id)

    dispatch<typeof setProfilePlaceAction.descriptor.shapes.fulfilled>({
      type: setProfilePlaceAction.descriptor.shapes.fulfilled.type,
      payload,
      meta: { cause: 'change' },
    })
  })
}

export function changePlace(payload: PlaceResponse) {
  return createThunk(async (dispatch, _getState, { router }) => {
    dispatch(setPlace(payload))
    router?.history.replace(undefined as any, { scroll: 'save' })
  })
}

export function setPlaceByRegion(regionId: string) {
  return createThunk(async (dispatch, _getState, { abort }) => {
    const abortController = abort.createAbortController('place-by-region-abort-controller')
    const placeResponse = (await dispatch(getPlaceByRegion(regionId)))!
    if (abortController.signal.aborted) return
    if (placeResponse.error) throw placeResponse.payload

    dispatch(changePlace(placeResponse.payload))
  })
}

export function restorePlace() {
  return createThunk(async (dispatch, getState, { abort, cookies }) => {
    const state = getState()
    const loading = state.profile.place.loading
    if (loading) return

    const extracted = dispatch(getPlaceId())
    const existing = state.profile.place.place_id
    if (existing && existing === extracted) return
    const abortController = abort.createAbortController(PLACE_ABORT_CONTROLLER_LABEL)

    dispatch<typeof setProfilePlaceAction.descriptor.shapes.pending>({
      type: setProfilePlaceAction.descriptor.shapes.pending.type,
      meta: { cause: 'restore' },
    })

    let placeResponse = (await dispatch(getPlaceById(extracted)))!

    if (abortController.signal.aborted) return

    if (placeResponse.error && asNetworkError(placeResponse.payload)?.status === 404) {
      placeCookie.rm(cookies)
      regionCookie.rm(cookies)
      placeResponse = (await dispatch(getPlaceById(dispatch(getDefaultPlaceId()))))!
    }

    if (abortController.signal.aborted) return

    if (placeResponse.error) {
      dispatch<typeof setProfilePlaceAction.descriptor.shapes.rejected>({
        type: setProfilePlaceAction.descriptor.shapes.rejected.type,
        error: true,
        payload: placeResponse.payload,
        meta: { cause: 'restore' },
      })

      throw placeResponse.payload
    }

    dispatch<typeof setProfilePlaceAction.descriptor.shapes.fulfilled>({
      type: setProfilePlaceAction.descriptor.shapes.fulfilled.type,
      payload: placeResponse.payload,
      meta: { cause: 'restore' },
    })

    return
  })
}

const PLACE_ABORT_CONTROLLER_LABEL = 'profile-place'

function getDefaultPlaceId() {
  return createThunk<string>((_dispatch, getState) => {
    return defaultRegionModelSelector(getState()).relationships.default_place.data!.id
  })
}

function getPlaceId() {
  return createThunk<string>((_dispatch, getState, { cookies }) => {
    const state = getState()
    const user = profileUserSelector(state)
    if (user && 'place_id' in user) {
      const id = isNil(user.place_id) ? null : String(user.place_id)
      if (id) return id
    }

    if (user && 'region_id' in user) {
      const regionId = !isNil(user.region_id) ? String(user.region_id) : null
      const region = (regionId && Object.values(regionsModelsSelector(state)).find(r => r.id === regionId)) || defaultRegionModelSelector(state)
      return region.relationships.default_place.data!.id
    }

    const placeId = placeCookie.get(cookies)
    if (placeId) return placeId

    const regionSlug = regionCookie.get(cookies)
    const region = Object.values(regionsModelsSelector(state)).find(r => r.attributes.slug === regionSlug) || defaultRegionModelSelector(state)
    return region.relationships.default_place.data!.id
  })
}

function convertProfileUpdateStateToProfilePatch(state: ProfileUpdateState): ProfileUpdateRequest {
  const patch = Object.fromEntries(
    Object.entries(state).flatMap(([key, value]): [string, any][] => {
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
      switch (key as keyof ProfileUpdateState) {
        case 'location': {
          if (!state.location) return []
          return [
            ['address', state.location.address],
            ['latitude', state.location.latitude],
            ['longitude', state.location.longitude],
          ]
        }
        case 'place': {
          const place = state.place
          if (!place) return [['place_id', undefined]]
          return [['place_id', place.place.id]]
        }
        default:
          return [[key, value]]
      }
    })
  ) as ProfileUpdateRequest
  return patch
}

export function fetchBoundIdentities() {
  return createThunk(async (dispatch, _getState): ApiActionPromise<null> => {
    const { getTelegramBotConnections } = await IMPORT_MAP.actions.telegram()
    const resps = await Promise.all([dispatch(getIdentities(true)), dispatch(getTelegramBotConnections())])
    const err = resps.find(resp => resp?.error)
    if (err && err.error) return err
    return {
      error: false,
      payload: null,
    }
  })
}
