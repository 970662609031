import type { ApiAnonymousParent, ApiAvatar, ApiIncluded, ApiPublicParentReview, ApiRequestReview, ApiSitter } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getPublicParentReviewsDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_PUBLIC_PARENT_REVIEWS').setShape<
  {
    data: ApiPublicParentReview[]
    included: (ApiAnonymousParent | ApiAvatar | ApiSitter)[]
    meta: { total: number; total_pages: number; current_page: number }
  },
  { query: { page: number; per_page: number } }
>()

export const getReviewsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_REVIEWS')
  .setShape<
    { data: ApiRequestReview[]; included?: ApiIncluded; meta: { total: number; total_pages: number; current_page: number } },
    { query: { page: number; per_page: number; user_token: string } }
  >()

export const postRequestReviewsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_REQUEST_REVIEWS')
  .setShape<{ data: ApiRequestReview }, { request_id: string; reviewer: 'sitter' | 'parent' }>()

export const postRequestReviewsQADescriptor = new ApiActionBuilderDescriptor().setLabel('POST_REQUEST_REVIEW_QA').setShape<unknown>()
