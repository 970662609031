import { putAnnouncementsByIdHideDescriptor } from '@app/store/actions/api/announcements.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: boolean }>({}, builder => {
  builder.addCase(putAnnouncementsByIdHideDescriptor.shapes.pending, (state, action) => {
    return { ...state, [action.meta.id]: true }
  })
  builder.addCase(putAnnouncementsByIdHideDescriptor.shapes.rejected, (state, action) => {
    return { ...state, [action.meta.id]: false }
  })
})
