import { ApiAvatar } from '@app/constants/ApiTypes/entities'

import { apiNormalize } from '@app/utils/apiNormalizer'
import { mergeMaps } from '@app/utils/mergeMaps'

import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiAvatar
}>({}, builder => {
  builder.defaultHandler = (state, action) => {
    const { avatars } = apiNormalize(action)
    if (!avatars) return state

    return mergeMaps(state, avatars)
  }
})
