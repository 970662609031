import { excludeAbortError } from '@app/errors/AbortError'

import { mapObject } from '@app/utils/mapOpject'
import { normalize } from '@app/utils/normalizer'

import {
  cancelAnnouncementResponseDescriptor,
  createAnnouncementResponseDescriptor,
  fetchAnnouncementResponseDescriptor,
} from '@app/store/actions/announcementResponse.descriptors'
import {
  getAnnouncementsByIdDescriptor,
  getAnnouncementsByQueryDescriptor,
  getAnnouncementsSearchDescriptor,
  getAnnouncementsUpcomingDescriptor,
  putAnnouncementsByIdCancelDescriptor,
} from '@app/store/actions/api/announcements.descriptors'
import { createReducer } from '@app/store/toolkit'
import { AnnouncementMeta } from '@app/store/types/announcements'

export default createReducer<{
  [key: string]: AnnouncementMeta
}>({}, builder => {
  builder.addCases(
    [
      getAnnouncementsByIdDescriptor.shapes.fulfilled,
      getAnnouncementsUpcomingDescriptor.shapes.fulfilled,
      getAnnouncementsSearchDescriptor.shapes.fulfilled,
      putAnnouncementsByIdCancelDescriptor.shapes.fulfilled,
      fetchAnnouncementResponseDescriptor.shapes.fulfilled,
      getAnnouncementsByQueryDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const { announcements } = normalize(action.payload)
      if (!announcements) return state

      return {
        ...state,
        ...mapObject(announcements, (): AnnouncementMeta => ({ updating: false, error: null })),
      }
    }
  )

  builder.addCases([cancelAnnouncementResponseDescriptor.shapes.fulfilled, createAnnouncementResponseDescriptor.shapes.fulfilled], (state, action) => {
    const { announcement_id } = action.meta
    return { ...state, [announcement_id]: { updating: false, error: null } }
  })

  builder.addCases([createAnnouncementResponseDescriptor.shapes.pending, cancelAnnouncementResponseDescriptor.shapes.pending], (state, action) => {
    const { announcement_id } = action.meta

    return { ...state, [announcement_id]: { updating: true, error: null } }
  })

  builder.addCases([putAnnouncementsByIdCancelDescriptor.shapes.pending], (state, action) => {
    const { id: id } = action.meta

    return { ...state, [id]: { updating: true, error: null } }
  })

  builder.addCases([createAnnouncementResponseDescriptor.shapes.rejected, cancelAnnouncementResponseDescriptor.shapes.rejected], (state, action) => {
    const { announcement_id } = action.meta
    return { ...state, [announcement_id]: { updating: false, error: excludeAbortError(action.payload) } }
  })

  builder.addCases([putAnnouncementsByIdCancelDescriptor.shapes.rejected], (state, action) => {
    const { id } = action.meta
    return { ...state, [id]: { updating: false, error: excludeAbortError(action.payload) } }
  })
})
