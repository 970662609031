import { isAbortError } from '@app/errors/AbortError'

import { sleep } from '@app/utils/sleep'

import { setBuildSha } from '@app/store/actions/misc.descriptors'
import { Store } from '@app/store/store'

export class BuildChecker {
  store: Store
  abortController: AbortController | null = null

  constructor(store: Store) {
    this.store = store
  }

  async start(
    /** Interval in ms */
    updateInterval: number
  ) {
    if (this.abortController) return
    const abortController = new AbortController()
    this.abortController = abortController
    while (true) {
      if (abortController.signal.aborted) break
      await this.fetchBuild(this.abortController.signal)
      if (abortController.signal.aborted) break
      await sleep(updateInterval)
    }
  }

  stop() {
    this.abortController?.abort()
    this.abortController = null
  }

  /**
   * fetches build.json and if build sha changed then store set needsReload property to `true`.
   * `needsReload` set to true indicates that router instead of History Api push will perform
   * hard move to next location i.e `window.location.href = newLocation`
   */
  private async fetchBuild(abortSignal: AbortSignal) {
    try {
      const resp = await fetch(`/assets/build.json?time=${new Date().getTime()}`, { signal: abortSignal })
      const json = await resp.json()
      this.store.dispatch(setBuildSha({ sha: json.sha }))
    } catch (e) {
      if (!isAbortError(e)) {
        console.error(e)
      }
    }
  }
}
