import { getNotificationSubscriptionId } from '@app/helpers/notification_subscriptions'

import { excludeAbortError } from '@app/errors/AbortError'

import {
  deleteNotificationsSubscriptionsByTopicDescriptor,
  postNotificationSubscriptionsByTopicDescriptor,
} from '@app/store/actions/api/notification_subscriptions.descriptors'
import { createReducer } from '@app/store/toolkit'

/** ApiNotificationSubscritpion Topic and channel joined with _ */
type SubscriptionID = string

export default createReducer<Record<SubscriptionID, { loading: boolean; error: Error | null } | undefined>>({}, builder => {
  builder.addCases(
    [postNotificationSubscriptionsByTopicDescriptor.shapes.pending, deleteNotificationsSubscriptionsByTopicDescriptor.shapes.pending],
    (state, action) => {
      return { ...state, [getNotificationSubscriptionId(action.meta)]: { loading: true, error: null } }
    }
  )

  builder.addCases(
    [postNotificationSubscriptionsByTopicDescriptor.shapes.fulfilled, deleteNotificationsSubscriptionsByTopicDescriptor.shapes.fulfilled],
    (state, action) => {
      return { ...state, [getNotificationSubscriptionId(action.meta)]: { loading: false, error: null } }
    }
  )

  builder.addCases(
    [postNotificationSubscriptionsByTopicDescriptor.shapes.rejected, deleteNotificationsSubscriptionsByTopicDescriptor.shapes.rejected],
    (state, action) => {
      return { ...state, [getNotificationSubscriptionId(action.meta)]: { loading: false, error: excludeAbortError(action.payload) } }
    }
  )
})
