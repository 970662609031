import { ApiAnnouncement } from '@app/constants/ApiTypes/entities'

import { mapObject } from '@app/utils/mapOpject'
import { mergeV2Entity } from '@app/utils/mergeV2Entity'
import { normalize } from '@app/utils/normalizer'

import { createAnnouncementResponseDescriptor, fetchAnnouncementResponseDescriptor } from '@app/store/actions/announcementResponse.descriptors'
import {
  getAnnouncementsByIdDescriptor,
  getAnnouncementsByQueryDescriptor,
  getAnnouncementsSearchDescriptor,
  getAnnouncementsUpcomingDescriptor,
  putAnnouncementsByIdCancelDescriptor,
} from '@app/store/actions/api/announcements.descriptors'
import { getSavedAnnouncementSearchesByIdAnnouncementsDescriptor } from '@app/store/actions/api/saved_announcement_searches.descriptors'
import { getDirectAnnouncementsIdDescriptor } from '@app/store/actions/directAnnouncement.descriptors'
import {
  cancelRequestDescriptor,
  createRequestFromAnnouncementResponseDescriptor,
  fetchRequestDescriptor,
  fetchRequestsDescriptor,
  getRequestsWithDebtsDescriptor,
  rejectRequestDescriptor,
} from '@app/store/actions/request.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiAnnouncement
}>({}, builder => {
  builder.addCases(
    [
      putAnnouncementsByIdCancelDescriptor.shapes.fulfilled,
      cancelRequestDescriptor.shapes.fulfilled,
      createRequestFromAnnouncementResponseDescriptor.shapes.fulfilled,
      getAnnouncementsByIdDescriptor.shapes.fulfilled,
      fetchAnnouncementResponseDescriptor.shapes.fulfilled,
      fetchRequestDescriptor.shapes.fulfilled,
      fetchRequestsDescriptor.shapes.fulfilled,
      getAnnouncementsByQueryDescriptor.shapes.fulfilled,
      getAnnouncementsSearchDescriptor.shapes.fulfilled,
      getAnnouncementsUpcomingDescriptor.shapes.fulfilled,
      getDirectAnnouncementsIdDescriptor.shapes.fulfilled,
      getRequestsWithDebtsDescriptor.shapes.fulfilled,
      getSavedAnnouncementSearchesByIdAnnouncementsDescriptor.shapes.fulfilled,
      rejectRequestDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const { announcements } = normalize(action.payload)
      if (!announcements) return state

      return {
        ...state,
        ...mapObject(announcements, a => mergeV2Entity(state[a.id], a)),
      }
    }
  )

  builder.addCase(createAnnouncementResponseDescriptor.shapes.fulfilled, (state, action) => {
    const { announcement_id } = action.meta
    const response_id = action.payload.data.id
    const model = state[announcement_id]

    if (!model) {
      return state
    }

    const announcement: ApiAnnouncement = {
      ...model,
      attributes: {
        ...model.attributes,
        responses_count: model.attributes.responses_count + 1,
      },
      relationships: {
        ...model.relationships,
        responses: {
          data: [
            ...(model.relationships.responses.data || []),
            {
              type: 'announcement_responses',
              id: response_id,
            },
          ],
        },
      },
    }

    return {
      ...state,
      [announcement_id]: announcement,
    }
  })
})
