export class Context {
  parent?: Context
  private store: Map<any, any> = new Map()

  getChildContext() {
    const ctx = new Context()
    ctx.parent = this
    return ctx
  }

  get<V extends object>(v: new () => V) {
    return this.store.get(v) as V | undefined
  }

  set<V extends object>(v: V) {
    this.store.set(v.constructor, v)
  }

  unset(v: new () => any) {
    this.store.delete(v)
  }
}
