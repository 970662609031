import { PlanModifier } from '@app/constants/ApiTypes/misc'

import * as api from '@app/utils/api'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import { getPlansDescriptor } from './plans.descriptors'

export const getPlans = new ApiActionBuilder(getPlansDescriptor)
  .setInit((modifier: PlanModifier = 'default') => ({
    method: 'GET',
    endpoint: api.path('/api/v2/plans', { campaign: modifier }),
    headers: api.headers(),
    meta: { modifier },
  }))
  .build()
