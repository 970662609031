export function mergeAbortSignals(...signals: AbortSignal[]) {
  const controller = new AbortController()

  function handleAbort() {
    controller.abort()

    for (const signal of signals) {
      signal.removeEventListener('abort', handleAbort)
    }
  }

  for (const signal of signals) {
    if (signal.aborted) {
      handleAbort()
      break
    }
    signal.addEventListener('abort', handleAbort)
  }

  return controller.signal
}
