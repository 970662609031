import { IMPORT_MAP } from '@app/importMap'

import { ApiLocation } from '@app/constants/ApiTypes/entities'

import { Address, createAddress } from '@app/packages/geo/Address'
import { Point } from '@app/packages/geo/Point'

import { Address as AddressClass } from './address'
import { LabelExtractor } from './addressTools'

export class AddressManager {
  address: Address

  constructor(address: Address) {
    this.address = address
  }

  isHouse() {
    return !!this.address.kind && ['house', 'entrance'].includes(this.address.kind)
  }

  coordsArray(): LocationLatLonArray {
    return [this.address.location.lat, this.address.location.lon]
  }

  mapsBounds(): [LocationLatLonArray, LocationLatLonArray] | undefined {
    if (!this.address.bounds) return undefined
    return [
      [this.address.bounds.bottomLeft.lat, this.address.bounds.bottomLeft.lon],
      [this.address.bounds.topRight.lat, this.address.bounds.topRight.lon],
    ]
  }

  asRectangle(): [[number, number], [number, number]] | undefined {
    if (!this.address.bounds) return undefined
    return [
      [this.address.bounds.bottomLeft.lat, this.address.bounds.bottomLeft.lon],
      [this.address.bounds.topRight.lat, this.address.bounds.topRight.lon],
    ]
  }

  asLocation() {
    return {
      address: this.address.label ?? '',
      king: this.address.kind,
      latitude: this.address.location.lat,
      longitude: this.address.location.lon,
    }
  }

  // eslint-disable-next-line deprecation/deprecation
  static fromAddressClass(address: AddressClass) {
    return new AddressManager(
      createAddress({
        label: address.label,
        kind: address.kind,
        location: {
          lat: address.latitude,
          lon: address.longitude,
        },
        bounds: address.bounds
          ? {
              bottomLeft: {
                lat: address.bounds[0][0],
                lon: address.bounds[0][1],
              },
              topRight: {
                lat: address.bounds[1][0],
                lon: address.bounds[1][1],
              },
            }
          : undefined,
      })
    )
  }

  static async fromLabel(label: string, extractLabel?: LabelExtractor) {
    const { getGeocodeData } = await IMPORT_MAP.utils.addressTools()
    const address = await getGeocodeData(label, { extractLabel })
    if (!address) return null
    return new AddressManager(
      createAddress({
        label: address.label,
        kind: address.kind,
        location: address.location,
        bounds: address.bounds,
      })
    )
  }

  static async fromPoint(point: Point, extractLabel?: LabelExtractor) {
    const { getGeocodeData } = await IMPORT_MAP.utils.addressTools()
    const address = await getGeocodeData(point, { extractLabel })
    if (!address) return null
    return new AddressManager(
      createAddress({
        label: address.label,
        kind: address.kind,
        location: address.location,
        bounds: address.bounds,
      })
    )
  }

  static fromLocation(location: ApiLocation['attributes']) {
    return new AddressManager(
      createAddress({
        label: location.address,
        location: {
          lat: location.latitude,
          lon: location.longitude,
        },
      })
    )
  }
}

type LocationLatLonArray = [latitude: number, longitude: number]
