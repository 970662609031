import jsnormalize from 'json-api-normalizer'

type ApiType = {
  id: string | number
  type: string
}

type Payload = {
  data: ApiType | ApiType[]
}

type DataType<T extends ApiType | ApiType[]> = T extends ApiType[] ? T[number] : T
type IncludedType<T extends ApiType[] | undefined> = Exclude<T, undefined>[number]

type PayloadTypes<P extends Payload> = DataType<P['data']> | (P extends { included?: ApiType[] } ? IncludedType<P['included']> : never)

export type ApiTypesMap<P extends ApiType> = { [K in P['type']]?: { [key: string]: Extract<P, { type: K }> } }

/** wrapper for `json-api-normalizer` that forbids camelizing of properties*/
export const normalize = <P extends Payload>(input: P): ApiTypesMap<PayloadTypes<P>> => jsnormalize(input, { camelizeKeys: false, camelizeTypeValues: false })
