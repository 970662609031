import type { ApiAnnouncement, ApiLocation } from '@app/constants/ApiTypes/entities'

import { AnnouncementsFilter } from '@app/packages/announcementsFilter/AnnouncementsFilter'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createAction, createPayloadlessAction } from '@app/store/toolkit'

export const setAnnouncementFilter = createAction<'setAnnouncementFilter', AnnouncementsFilter>('setAnnouncementFilter')

export const resetAnnouncementErrors = createPayloadlessAction('ANNOUNCEMENT_CREATE_RESET_ERRORS')

export const getMapAnnouncementsSearchDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_MAP_ANNOUNCEMENTS_SEARCH').setShape<
  {
    data: {
      type: ApiAnnouncement['type']
      id: ApiAnnouncement['id']
      relationships: {
        location: { data: { id: string } }
      }
    }[]
    included?: {
      type: ApiLocation['type']
      id: ApiLocation['id']
      attributes: {
        longitude: number
        latitude: number
      }
    }[]
    meta: { cursor: string }
  },
  { initial: boolean }
>()
