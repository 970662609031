// eslint-disable-next-line no-restricted-imports
import _moment from 'moment-timezone'
// eslint-disable-next-line no-restricted-imports
import { Moment as _Moment } from 'moment-timezone'

const moment = _moment

export default moment

export type Moment = _Moment

export function assertTimezoneValid(tz: string) {
  if (!moment.tz.zone(tz)) throw new Error(`Timezone "${tz}" is invalid`)
}
