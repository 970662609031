import { ApiInaccurateLocation } from '@app/constants/ApiTypes/entities'

import { apiNormalize } from '@app/utils/apiNormalizer'

import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: ApiInaccurateLocation }>({}, builder => {
  builder.defaultHandler = (state, action) => {
    const { inaccurate_locations } = apiNormalize(action)
    return { ...state, ...inaccurate_locations }
  }
})
