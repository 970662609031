import { ApiSavedAnnouncementSearch } from '@app/constants/ApiTypes/entities'

import { replaceOrAppend } from '@app/utils/replaceOrAppend'

import {
  deleteSavedAnnouncementSearchesByIdDescriptor,
  getSavedAnnouncementSearchesByIdDescriptor,
  getSavedAnnouncementSearchesDescriptor,
  postSavedAnnouncementSearchesDescriptor,
} from '@app/store/actions/api/saved_announcement_searches.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiSavedAnnouncementSearch[]>([], builder => {
  builder.addCases([postSavedAnnouncementSearchesDescriptor.shapes.fulfilled, getSavedAnnouncementSearchesByIdDescriptor.shapes.fulfilled], (state, action) => {
    return replaceOrAppend(state, action.payload.data, item => item.id === action.payload.data.id)
  })
  builder.addCase(getSavedAnnouncementSearchesDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data
  })
  builder.addCase(deleteSavedAnnouncementSearchesByIdDescriptor.shapes.fulfilled, (state, action) => {
    return state.filter(item => item.id !== action.meta.id)
  })
})
