import { ApiCurrency } from '@app/constants/ApiTypes/entities'

import * as api from '@app/utils/api'
import { createCookie } from '@app/utils/cookies'
import moment from '@app/utils/moment'
import { DEFAULT_CURRENCY } from '@app/utils/printCurrency'

import { withAbortSignal } from '@app/packages/abortContext/actions'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'
import { profileCurrencySelector, profileRegionSelector } from '@app/store/selectors/profile'
import { createThunk } from '@app/store/thunk'

import { PriceInputValue } from '@app/components/PriceInput/PriceInputValue'

import { getCurrenciesDescriptor, getCurrencyRatesDescriptor, setCurrency } from './currencies.descriptors'
import { updateProfile } from './profile'

export const getCurrencies = new ApiActionBuilder(getCurrenciesDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.path('/api/v2/currencies'),
    headers: api.headers(),
  }))
  .build()

export const getCurrencyRates = new ApiActionBuilder(getCurrencyRatesDescriptor)
  .setInit((base: string) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/currencies/rates', { base }),
    headers: api.headers(),
    meta: { base },
  }))
  .build()

const currencyCookie = createCookie<string>(
  'kidosut__currency',
  value => value,
  value => value,
  () => ({ expires: moment().add(1, 'year').toDate() })
)

export function changeCurrency(currency: ApiCurrency, persist = false) {
  return createThunk(async (dispatch, getState, { abort, cookies }) => {
    const abortController = abort.createAbortController('currency-change')
    return dispatch(
      withAbortSignal(abortController.signal, async dispatch => {
        const rateRes = await dispatch(getCurrencyRates(currency.attributes.iso_code))
        if (rateRes?.error) return rateRes
        if (persist) {
          currencyCookie.set(cookies, currency.attributes.iso_code)
          const user = getState().profile.user
          if (user && user.account_type !== 'visitor') {
            const profileRes = await dispatch(updateProfile({ display_currency: currency.attributes.iso_code }))
            if (profileRes?.error) return profileRes
          }
        }

        dispatch(setCurrency(currency))
      })
    )
  })
}

export function restoreCurrency() {
  return createThunk(async (dispatch, _getState) => {
    const currency = dispatch(restoreUserCurrency()) ?? dispatch(restoreCookieCurrency()) ?? dispatch(restoreDefaultCurrency())

    const resp = await dispatch(getCurrencyRates(currency.attributes.iso_code))
    if (resp?.error) return resp

    dispatch(setCurrency(currency))
  })
}

export function getEmptyPriceInputValue() {
  return createThunk((_dispath, getState) => {
    return PriceInputValue.empty().setCurrency(profileCurrencySelector(getState()))
  })
}

export function compareCurrencies(a: PriceInputValue, b: PriceInputValue) {
  return createThunk(async (dispatch, getState) => {
    if (a.currency === b.currency) {
      return compare(a.floatValue, b.floatValue)
    }
    const ts = getState().currency_rates_meta[DEFAULT_CURRENCY]?.timestamp
    if (!ts || moment.unix(ts).add(20, 'minutes').isBefore(moment())) {
      const resp = await dispatch(getCurrencyRates(DEFAULT_CURRENCY))
      if (resp?.error) throw resp?.payload
    }
    const an = dispatch(toBaseCurrency(a))
    const bn = dispatch(toBaseCurrency(b))
    return compare(an, bn)
  })
}

function getCurrencyByCode(code: string) {
  return createThunk((_dispatch, getState) => {
    const list = Object.values(getState().currencies)
    return list.find(c => c.attributes.iso_code === code)
  })
}

function restoreUserCurrency() {
  return createThunk((dispatch, getState) => {
    const state = getState()
    if (state.profile.user && 'display_currency' in state.profile.user) {
      return dispatch(getCurrencyByCode(state.profile.user.display_currency)) ?? dispatch(getCurrencyByCode(DEFAULT_CURRENCY))!
    }

    return null
  })
}

function restoreCookieCurrency() {
  return createThunk((dispatch, _getState, { cookies }) => {
    const value = currencyCookie.get(cookies)
    if (!value) return null

    return dispatch(getCurrencyByCode(value)) ?? null
  })
}

function restoreDefaultCurrency() {
  return createThunk((dispatch, getState) => {
    const region = profileRegionSelector(getState())
    return dispatch(getCurrencyByCode(region.attributes.currency)) ?? dispatch(getCurrencyByCode(DEFAULT_CURRENCY))!
  })
}

function toBaseCurrency(a: PriceInputValue) {
  return createThunk((_dispatch, getState) => {
    if (a.currency === DEFAULT_CURRENCY) return a.floatValue
    const rate = Object.values(getState().currency_rates).find(c => c.attributes.from === a.currency && c.attributes.to === DEFAULT_CURRENCY)!
    return a.floatValue * rate.attributes.rate
  })
}

function compare(a: number, b: number) {
  if (a === b) return 0
  return a > b ? 1 : -1
}
