import keyBy from 'lodash/keyBy'

import { Point } from '@app/packages/geo/Point'

import { createReducer } from '@app/store/toolkit'

import { reducedSitterSearchRequestDescriptor } from '@app/routes/Search/actions.descriptors'

export default createReducer<Record<string, { id: string; token: string; name: string; coords: Point }>>({}, builder => {
  builder.addCase(reducedSitterSearchRequestDescriptor.shapes.fulfilled, (state, action) => {
    const newState = action.meta.paramsChanged ? {} : { ...state }
    const locations = keyBy(action.payload.included, 'id')
    action.payload.data.forEach(u => {
      const loc = locations[u.relationships.inaccurate_location.data!.id]
      const us = {
        id: u.id,
        token: u.attributes.token,
        name: u.attributes.name,
        coords: { lat: loc.attributes.rough_latitude, lon: loc.attributes.rough_longitude },
      }

      newState[us.token] = us
    })
    return newState
  })
})
