import { ApiPromotedSitter } from '@app/constants/ApiTypes/entities'

import { excludeAbortError } from '@app/errors/AbortError'

import { getSittersPromotedDescriptor } from '@app/store/actions/api/sitters.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  models: ApiPromotedSitter[]
  pending: boolean
  loaded: boolean
  error: Error | null
}>(
  {
    models: [],
    pending: false,
    loaded: false,
    error: null,
  },
  builder => {
    builder.addCase(getSittersPromotedDescriptor.shapes.pending, (state, _action) => {
      return { ...state, pending: true, loaded: false, error: null }
    })

    builder.addCase(getSittersPromotedDescriptor.shapes.fulfilled, (state, action) => {
      return { ...state, pending: false, loaded: true, error: null, models: action.payload.data }
    })

    builder.addCase(getSittersPromotedDescriptor.shapes.rejected, (state, action) => {
      return { ...state, pending: false, loaded: true, error: excludeAbortError(action.payload) }
    })
  }
)
