import { createSelector } from 'reselect'

import { Raw, urlEscaped } from '@app/utils/urlEscaped'

import { StoreState } from '@app/store/store'

/** Returns map of users */
export const usersStateSelector = (state: StoreState) => state.users
export const usersSelector = (state: StoreState) => state.users.models
export const usersMetaSelector = (state: StoreState) => state.users.meta

/** Returns list of users */
export const usersListSelector = createSelector([usersSelector], userMap => Object.values(userMap))

export const makeUserByIdSelector = (userIdSelector: (state: StoreState, props?: any) => string | null) =>
  createSelector([usersSelector, userIdSelector], (users, userId) => (!userId ? null : users[userId] || null))

export const makeUsersByIdSelector = (userIdsSelector: (state: StoreState, props?: any) => string[]) =>
  createSelector([usersSelector, userIdsSelector], (users, ids) =>
    ids
      .map(id => {
        const user = users[id]
        if (!user && process.env.NODE_ENV === 'development') console.error(`user with id ${id} not found`)
        return user
      })
      .filter(u => !!u)
  )

export const metaTagsSelector = (state: StoreState) => state.users.meta_tags

export const createSitterSelector = (id: string) =>
  createSelector([usersSelector, () => id], (users, sitterId) => {
    const user = users[sitterId]
    if (!user || user.account_type !== 'sitter') return null
    return user
  })

export const createSitterMetaTagsSelector = (id: string) =>
  createSelector([metaTagsSelector, () => id], (meta, sitterId) => {
    const tags = meta[sitterId] ? meta[sitterId] : null
    return tags
  })

export const createUserURLSelector = (id: string) => {
  const sitterSelecror = createSitterSelector(id)
  const metaSelector = createSitterMetaTagsSelector(id)

  return createSelector([sitterSelecror, metaSelector], (sitter, meta) =>
    sitter && meta ? urlEscaped`/${sitter.token}/${new Raw(meta.attributes.hurl_suffix)}` : sitter ? urlEscaped`/${sitter.token}` : '/'
  )
}

export const createSubscriptionStatusSelector = (id: string) => (state: StoreState) => {
  if (state.users.models[id]?.favorite) return 'favorite'
  if (state.ignored.includes(id)) return 'ignored'
  return null
}
