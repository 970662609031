import { format as lformat } from 'libphonenumber-js'

/** format phone to +x (xxx) xxx-xx-xx format */
export function format(input: string): string {
  let local = false
  let normalized = input.replace(/\s+/g, '').replace(/\++/g, '+')

  if (normalized.startsWith('8')) {
    local = true
    normalized = '+7' + normalized.substring(1)
  }

  normalized = '+' + normalized.replace(/\D+/g, '')

  return local ? lformat(normalized, 'NATIONAL') : lformat(normalized, 'INTERNATIONAL')
}
