import uniq from 'lodash/uniq'

import {
  deleteIgnoredUserDescriptor,
  getIgnoredUserDescriptor,
  getIgnoredUserListDescriptor,
  postIgnoredUserDescriptor,
} from '@app/store/actions/api/ignored.descriptors'
import { addToFavoritesDescriptor } from '@app/store/actions/profile.descriptors'
import { asNetworkError } from '@app/store/apiMiddleware/errors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[]>([], builder => {
  builder.addCase(getIgnoredUserListDescriptor.shapes.fulfilled, (state, action) => {
    const ids = action.payload.data.map(u => u.attributes.user_id)
    if (action.meta.page < 2) return ids
    return [...state, ...ids]
  })
  builder.addCase(postIgnoredUserDescriptor.shapes.fulfilled, (state, action) => {
    return uniq([...state, action.meta.user_id])
  })
  builder.addCase(deleteIgnoredUserDescriptor.shapes.fulfilled, (state, action) => {
    return state.filter(id => id !== action.meta.userId)
  })
  builder.addCase(getIgnoredUserDescriptor.shapes.fulfilled, (state, action) => {
    return uniq([...state, action.payload.data.attributes.user_id])
  })
  builder.addCase(getIgnoredUserDescriptor.shapes.rejected, (state, action) => {
    if (asNetworkError(action.payload)?.status === 404) return state.filter(id => id !== action.meta.userId)
    return state
  })
  builder.addCase(addToFavoritesDescriptor.shapes.fulfilled, (state, action) => {
    const uid = String(action.meta.user_id)
    return state.filter(id => id !== uid)
  })
})
