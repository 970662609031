import { ApiPlace } from '@app/constants/ApiTypes/entities'

import { apiNormalize } from '@app/utils/apiNormalizer'

import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiPlace
}>({}, builder => {
  builder.defaultHandler = (state, action) => {
    const { places } = apiNormalize(action)
    if (!places) return state
    return { ...state, ...places }
  }
})
