import * as api from '@app/utils/api'
import { urlEscaped } from '@app/utils/urlEscaped'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import { getPlacesByIdDescriptor, getPlacesByLocationDescriptor } from './places.descriptors'

export const getPlacesById = new ApiActionBuilder(getPlacesByIdDescriptor)
  .setInit((id: string) => ({
    method: 'GET',
    endpoint: api.path(urlEscaped`/api/v2/places/${id}`, {
      include: 'region,country,locality',
    }),
    headers: api.headers(),
    meta: { id },
  }))
  .build()

export const getPlacesByLocation = new ApiActionBuilder(getPlacesByLocationDescriptor)
  .setInit((request: { latitude: number; longitude: number }) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/places/by_location', {
      latitude: request.latitude,
      longitude: request.longitude,
      include: 'region,country,locality',
    }),
    headers: api.headers(),
    meta: request,
  }))
  .build()
