import { Result, resultError, resultOk } from '@app/packages/Result/Result'

import { asError } from './asError'

export function tryJSONparse<T>(data?: string | null): Result<T> {
  try {
    const value = JSON.parse(data || '')
    return resultOk(value)
  } catch (e) {
    return resultError(asError(e))
  }
}
