import { mergeAbortSignals } from '@app/utils/mergeAbortSignals'

export class AbortSignalsManager {
  signals: { value: AbortSignal }[] = []

  add(signal: AbortSignal) {
    const val = { value: signal }
    this.signals.push(val)

    return () => {
      this.signals = this.signals.filter(v => v !== val)
    }
  }

  getSignal() {
    if (!this.signals.length) return undefined

    return mergeAbortSignals(...this.signals.map(s => s.value))
  }
}
