import { isAbortError } from '@app/errors/AbortError'

import { setAnnouncementFilter } from '@app/store/actions/announcement.descriptors'
import { getAnnouncementsSearchDescriptor, getAnnouncementsUpcomingDescriptor } from '@app/store/actions/api/announcements.descriptors'
import { createReducer } from '@app/store/toolkit'
import { AnnouncementsMetaState } from '@app/store/types/announcements'

export default createReducer<AnnouncementsMetaState>({ loading: false, filter: {} }, builder => {
  builder.addCase(setAnnouncementFilter.shape, (state, action) => {
    return { ...state, filter: action.payload }
  })
  builder.addCase(getAnnouncementsSearchDescriptor.shapes.pending, (state, _action) => {
    return { ...state, loading: true }
  })
  builder.addCase(getAnnouncementsSearchDescriptor.shapes.fulfilled, (state, action) => {
    return {
      ...state,
      loading: false,
      cursor: action.payload.meta.current_page >= action.payload.meta.total_pages ? null : action.payload.meta.cursor,
    }
  })
  builder.addCase(getAnnouncementsSearchDescriptor.shapes.rejected, (state, action) => {
    if (isAbortError(action.payload)) return state
    return { ...state, loading: false }
  })

  builder.addCase(getAnnouncementsUpcomingDescriptor.shapes.pending, (state, _action) => {
    return { ...state, loading: true }
  })
  builder.addCase(getAnnouncementsUpcomingDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, page: 1, total: action.payload.data.length, total_pages: 1, loading: false }
  })
  builder.addCase(getAnnouncementsUpcomingDescriptor.shapes.rejected, (state, action) => {
    if (isAbortError(action.payload)) return state
    return { ...state, loading: false }
  })
})
