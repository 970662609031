import { NotificationTopicGroup } from '@app/constants/NotificationTypes'

import { getNotificationSubscriptionsLayoutsDescriptor } from '@app/store/actions/api/notification_subscriptions_layouts.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<NotificationTopicGroup[]>([], builder => {
  builder.addCase(getNotificationSubscriptionsLayoutsDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data.attributes.layout
  })
})
