import { ApiAnnouncement, ApiAnnouncementResponse, ApiAvatar, ApiLocation, ApiParent, ApiSitter, ApiUser } from '@app/constants/ApiTypes/entities'
import { ListResponseMeta } from '@app/constants/ApiTypes/misc'
import { AnnouncementSearchQuery } from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getMapAnnouncementsSearchDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_MAP_ANNOUNCEMENTS_SEARCH').setShape<{
  data: {
    type: ApiAnnouncement['type']
    id: ApiAnnouncement['id']
    relationships: {
      location: { data: { id: string } }
    }
  }[]
  included?: {
    type: ApiLocation['type']
    id: ApiLocation['id']
    attributes: {
      longitude: number
      latitude: number
    }
  }[]
  meta: { cursor: string }
}>()

export const getAnnouncementsSearchDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getAnnouncementsSearch')
  .setShape<
    { data: ApiAnnouncement[]; included?: (ApiLocation | ApiAvatar | ApiParent | ApiSitter | ApiAnnouncementResponse)[]; meta: ListResponseMeta },
    { type: 'cursor'; cursor: string } | { type: 'query'; query: AnnouncementSearchQuery }
  >()

export const getAnnouncementsSearchCountDescriptor = new ApiActionBuilderDescriptor().setLabel('getAnnouncementsSearchCount').setShape<{ total: number }>()

export const getAnnouncementsUpcomingDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getAnnouncementsUpcoming')
  .setShape<{ data: ApiAnnouncement[]; included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[] }>()

export const getAnnouncementsByQueryDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getAnnouncementsByQuery')
  .setShape<{ data: ApiAnnouncement[]; included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[] }>()

export const getAnnouncementsByIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getAnnouncementsById')
  .setShape<{ data: ApiAnnouncement; included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[] }, { id: string }>()

export const postAnnouncementsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postAnnouncements')
  .setShape<{ data: ApiAnnouncement; included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[] }>()

export const putAnnouncementsByIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putAnnouncementsById')
  .setShape<{ data: ApiAnnouncement; included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[] }>()

export const putAnnouncementsByIdCancelDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putAnnouncementsByIdCancel')
  .setShape<{ data: ApiAnnouncement; included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[] }, { id: string }>()

export const putAnnouncementsByIdHideDescriptor = new ApiActionBuilderDescriptor().setLabel('putAnnouncementsByIdHide').setShape<undefined, { id: string }>()
