import { BasicApiActionBuilderAction, createApiActionStub } from './builder'

export type Interceptor<A extends BasicApiActionBuilderAction> = (input: {
  label: string
  args: Parameters<A>
}) => ((...args: Parameters<A>) => ReturnType<A>) | undefined | 'pass'

export class ApiInterceptor {
  interceptors: Interceptor<BasicApiActionBuilderAction>[] = []

  intercept(interceptor: Interceptor<BasicApiActionBuilderAction>) {
    this.interceptors.unshift(interceptor)
    return () => {
      this.interceptors = this.interceptors.filter(i => i !== interceptor)
    }
  }

  interceptApiAction<A extends BasicApiActionBuilderAction>(action: A, mock: (...args: Parameters<A>) => ReturnType<A>) {
    return this.intercept(({ label }) => {
      if (label === action.label) return createApiActionStub(action, mock)
      return undefined
    })
  }

  allowApiAction<A extends BasicApiActionBuilderAction>(action: A) {
    return this.intercept(({ label }) => {
      if (label === action.label) return 'pass'
      return undefined
    })
  }
}
