import { ApiLocality } from '@app/constants/ApiTypes/entities'

import { apiNormalize } from '@app/utils/apiNormalizer'

import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiLocality
}>({}, builder => {
  builder.defaultHandler = (state, action) => {
    const { localities } = apiNormalize(action)
    if (!localities) return state

    return { ...state, ...localities }
  }
})
