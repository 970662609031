import { getNotificationSubscriptionsDescriptor } from '@app/store/actions/api/notification_subscriptions.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  error: Error | null
  loaded: boolean
}>(
  {
    error: null,
    loaded: false,
  },
  builder => {
    builder.addCase(getNotificationSubscriptionsDescriptor.shapes.fulfilled, () => {
      return { error: null, loaded: true }
    })
  }
)
