import React from 'react'
import { NavigationType } from 'react-router'
import type Cookies from 'universal-cookie'

import { FetchDataError } from '@app/errors/FetchDataError'

import { Progress } from '@app/services/Progress/Progress'

import { performFetchData } from '@app/utils/performFetchData'
import { matchRoutes } from '@app/utils/routing/functions'
import { StaticRedirect } from '@app/utils/routing/StaticRedirect'
import { Action, LocationDescriptorObject, MatchedRoute, Route, RouterInterface } from '@app/utils/routing/types'

import { routerRaiseError } from '@app/store/actions/misc.descriptors'
import { beforeNavigate, navigate } from '@app/store/actions/router.descriptors'
import { availableRegionsSlugsSelector } from '@app/store/selectors/regions'
import { Store } from '@app/store/store'

import { ContextProvider } from '@app/components/ContextProvider/ContextProvider'

export default async function ({
  router: Router,
  location,
  getRoutes,
  onRouteChange,
  store,
  cookies,
  afterRender,
  handleError = e => {
    throw e
  },
}: {
  router: RouterInterface
  location: LocationDescriptorObject
  getRoutes: (location: LocationDescriptorObject) => Promise<Route[]>
  onRouteChange?: (location: LocationDescriptorObject, action: Action, matchedRoutes: MatchedRoute[]) => Promise<StaticRedirect | void>
  store: Store
  cookies: Cookies
  afterRender?: (location: LocationDescriptorObject, matchedRoutes: MatchedRoute[], hardNavigation: boolean) => Promise<unknown>
  handleError?: (e: unknown) => void
}) {
  const storeState = store.getState()
  const regions = availableRegionsSlugsSelector(storeState)

  let initial = true

  let routes = await getRoutes(location)

  const onBeforeLocationChange = async (location: LocationDescriptorObject) => {
    try {
      if (IS_BROWSER && store.getState().config.build.needsReload) {
        await redirectToLocation(location)
      }
      routes = await getRoutes(location)
    } catch (e) {
      const err = FetchDataError.create("Can't load page", 500)
      store.dispatch(
        routerRaiseError({
          errorCode: err.status,
          message: err.message,
          localizedMessage: err.localizedMessage,
        })
      )
      if (IS_BROWSER) {
        // try to hard navigate to new location
        // in case when we deployed app and routes chunks url have changed
        await redirectToLocation(location)
      }

      throw e
    }
  }

  const processMatchedRoutes = async (location: LocationDescriptorObject, action: Action, matchedRoutes: MatchedRoute[]) => {
    const { error } = store.getState()

    if (error.code) return

    const regionRedirect = await onRouteChange?.(location, action, matchedRoutes)
    if (regionRedirect) return regionRedirect

    const fetchDataRedirect = await performFetchData(location, action, matchedRoutes, store)
    if (fetchDataRedirect) return fetchDataRedirect
  }

  const loadData = async (location: LocationDescriptorObject, action: Action, matchedRoutes: MatchedRoute[]): Promise<void | StaticRedirect> => {
    const match = matchedRoutes.at(-1) ?? null
    const showProgress = !initial && match?.route.progress !== false && location.state?.progress !== false
    const routerState = { ...match!, location, action }
    store.dispatch(beforeNavigate({ state: routerState, initial }))

    let promise = processMatchedRoutes(location, action, matchedRoutes)
    if (showProgress) promise = Progress.shared.wrap(promise)
    const redirect = await promise.catch(handleError)

    store.dispatch(navigate(routerState))
    if (IS_BROWSER) initial = false
    if (redirect) return redirect
  }

  const redirect = await loadData(location, NavigationType.Push, matchRoutes(regions, routes, location.pathname!))

  const obtainRoutes = () => routes

  return (
    <ContextProvider cookies={cookies} store={store}>
      <Router
        afterRender={afterRender}
        getRoutes={obtainRoutes}
        onBeforeLocationChange={onBeforeLocationChange}
        onLocationChange={loadData}
        redirect={redirect || undefined}
      />
    </ContextProvider>
  )
}

async function redirectToLocation(location: LocationDescriptorObject): Promise<never> {
  window.location.href = `${location.pathname || ''}${location.search || ''}${location.hash ?? ''}`
  return await new Promise<never>(_resolve => {})
}
