import React, { CSSProperties, FunctionComponent, PropsWithChildren, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

export type WidthExtractor = (anchorWidth: number) => number

/** Component that renders its content in menu portal */
export const Menu: FunctionComponent<
  PropsWithChildren<{
    anchor: RefObject<Element>
    translateX?: number
    translateY?: number
    offsetX?: number
    offsetY?: number
    targetOffsetX?: number
    targetOffsetY?: number

    width?: number | WidthExtractor
  }>
> = ({ anchor, children, translateX = 0, translateY = 0, offsetX = 0, offsetY = 1, targetOffsetX = 0, targetOffsetY = 0, width: pwidth }) => {
  const el = IS_BROWSER ? document.getElementById('menuportal') : null
  const elRef = useRef<HTMLDivElement>(null)

  const [top, setTop] = useState<number | null>(null)
  const [left, setLeft] = useState<number | null>(null)
  const [width, setWidth] = useState<number>(300)

  const style = useMemo(
    (): CSSProperties | null =>
      top === null || left === null
        ? null
        : {
            position: 'absolute',
            top: top + translateY,
            left: left + translateX,
            width: typeof pwidth === 'number' ? pwidth : typeof pwidth === 'function' ? pwidth(width) : undefined,
            transition: 'top 0.2s 0.1s ease-in-out, left 0.2s 0.1s ease-in-out',
          },
    [top, left, translateY, translateX, pwidth, width]
  )

  useEffect(() => {
    const anc = anchor?.current
    const el = elRef.current
    if (!anc || !el) return

    let stopped = false

    let last = 0

    const handler = (ts: number) => {
      if (ts - last > 10) {
        last = ts
        const rect = anc.getBoundingClientRect()
        const elRect = el.getBoundingClientRect()

        const w = elRect.width
        const h = elRect.height

        setTop(window.scrollY + rect.top + rect.height * offsetY - h * targetOffsetY)
        setLeft(Math.min(window.innerWidth - w, Math.max(0, window.scrollX + rect.left + rect.width * offsetX - w * targetOffsetX)))

        setWidth(rect.width)

        if (stopped) return
      }

      requestAnimationFrame(handler)
    }
    requestAnimationFrame(handler)

    return () => {
      stopped = true
    }
  }, [anchor, offsetX, offsetY, pwidth, targetOffsetX, targetOffsetY])

  return el
    ? createPortal(
        <div ref={elRef} style={style ?? undefined}>
          {!!style && children}
        </div>,
        el
      )
    : null
}
