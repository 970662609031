/**
 * Async imports map.
 *
 * Main purpose is to manage webpack chunk name and avoid of setting different chunk name in multiple imports of same file
 */
export const IMPORT_MAP = {
  hls: () => import(/* webpackChunkName: "hls" */ 'hls.js'),
  sourcemapped_stacktrace: () => import(/* webpackChunkName: "sourcemapped_stacktrace" */ 'sourcemapped-stacktrace'),

  twilio: () => import('@twilio/conversations'), // chunk name defined in webpack chunk group
  twilioManager: () => import(/* webpackChunkName: "twilio_manager" */ '@app/services/TwilioManager'),
  firebase: {
    manager: () => import(/* webpackChunkName: "firebase_manager" */ '@app/services/FirebaseManager'),
    messagingManager: () => import(/* webpackChunkName: "firebase_manager" */ '@app/services/FirebaseMessagingManager'),
    app: () => import('firebase/app'), // chunk name defined in webpack chunk group
    database: () => import('firebase/database'), // chunk name defined in webpack chunk group
    auth: () => import('firebase/auth'), // chunk name defined in webpack chunk group
    messaging: () => import('firebase/messaging'), // chunk name defined in webpack chunk group
  },
  intl: {
    intl: () => import(/* webpackChunkName: "intl" */ 'intl'),
    localePolyfill: () => import(/* webpackChunkName: "intl" */ '@formatjs/intl-locale/polyfill'),
    pluralRulesPolyfill: () => import(/* webpackChunkName: "intl" */ '@formatjs/intl-pluralrules/polyfill'),
    relativeTimeFormatPolyfill: () => import(/* webpackChunkName: "intl" */ '@formatjs/intl-relativetimeformat/polyfill'),
    LocaleData: {
      ru: () => import(/* webpackChunkName: "intl-ru" */ 'intl/locale-data/jsonp/ru'),
      en: () => import(/* webpackChunkName: "intl-en" */ 'intl/locale-data/jsonp/en'),
    },
    PluralRules: {
      ru: () => import(/* webpackChunkName: "intl-ru" */ '@formatjs/intl-pluralrules/locale-data/ru'),
      en: () => import(/* webpackChunkName: "intl-en" */ '@formatjs/intl-pluralrules/locale-data/en'),
    },
    RelativeTimeFormat: {
      ru: () => import(/* webpackChunkName: "intl-ru" */ '@formatjs/intl-relativetimeformat/locale-data/ru'),
      en: () => import(/* webpackChunkName: "intl-en" */ '@formatjs/intl-relativetimeformat/locale-data/en'),
    },
  },
  actions: {
    currencies: () => import(/* webpackChunkName: "actions-currencies" */ '@app/store/actions/currencies'),
    profile: () => import(/* webpackChunkName: "actions-profile" */ '@app/store/actions/profile'),
    session: () => import(/* webpackChunkName: "actions-session" */ '@app/store/actions/session'),
    ui: () => import(/* webpackChunkName: "actions-ui" */ '@app/store/actions/ui'),
    misc: () => import(/* webpackChunkName: "actions-misc" */ '@app/store/actions/misc'),
    menu: () => import(/* webpackChunkName: "actions-menu" */ '@app/store/actions/menu'),
    request: () => import(/* webpackChunkName: "actions-request" */ '@app/store/actions/request'),
    academy: () => import(/* webpackChunkName: "actions-academy" */ '@app/store/actions/academy'),
    faye: () => import(/* webpackChunkName: "actions-faye" */ '@app/store/actions/faye'),
    news: () => import(/* webpackChunkName: "actions-news" */ '@app/store/actions/news'),
    trusted: () => import(/* webpackChunkName: "actions-trusted" */ '@app/store/actions/trusted'),
    payment: () => import(/* webpackChunkName: "actions-payment" */ '@app/store/actions/payment'),
    telegram: () => import(/* webpackChunkName: "actions-telegram" */ '@app/store/actions/telegram'),
    twilio: () => import(/* webpackChunkName: "actions-twilio" */ '@app/store/actions/twilio'),
  },
  components: {
    Payment: () => import(/* webpackChunkName: "components_payment" */ '@app/components/Payment/Payment'),

    Redirect: () => import(/* webpackChunkName: "components_redirect" */ '@app/utils/routing/Redirect'),

    PushNotificationsBanner: () => import(/* webpackChunkName: "components_banners" */ '@app/components/PushNotificationsBanner/PushNotificationsBanner'),
    DebtsStatusBanner: () => import(/* webpackChunkName: "components_banners" */ '@app/components/DebtsStatusBanner/DebtsStatusBanner'),
    CommissionPaymentBanner: () => import(/* webpackChunkName: "components_banners" */ '@app/components/CommissionPaymentBanner/CommissionPaymentBanner'),
    AddTelegramBanner: () => import(/* webpackChunkName: "components_banners" */ '@app/components/AddTelegramBanner/AddTelegramBanner'),
    SocialNetworkBindBanner: () => import(/* webpackChunkName: "components_banners" */ '@app/components/SocialNetworkBindBanner/SocialNetworkBindBanner'),

    WorkTodayCheckbox: () => import(/* webpackChunkName: "components_work_today" */ '@app/components/WorkToday/WorkTodayCheckbox'),
    CurrencySelect: () => import(/* webpackChunkName: "components_currency" */ '@app/components/CurrencySelect/CurrencySelect'),

    AddressMap: () => import(/* webpackChunkName: "components_address_map" */ '@app/components/AddressMap/AddressMap'),

    FocusLocker: () => import('react-focus-lock'), // let webpack decides the chunk
    AcademySubscription: () => import('@app/routes/Academy/Subscription'),
  },
  modals: {
    Phone: () => import('@app/components/Phone'),
    AcademySuccessModal: () => import('@app/routes/Academy/AcademySuccessModal'),
    AcademyCartPurchaseModal: () => import('@app/routes/Academy/AcademyCartPurchaseModal'),
    AcademyMobileCart: () => import('@app/routes/Academy/MobileCart'),
    AcademyQuickCart: () => import('@app/routes/Academy/QuickCart'),
    AcademyFilterModal: () => import('@app/routes/Academy/FilterModal'),
    TOSModal: () => import('@app/components/TOSAccept/TOSModal'),
    ChargeModal: () => import('@app/components/ChargeModal/ChargeModal'),
    DebtsModal: () => import('@app/components/DebtsModal/DebtsModal'),
    TrustedSitterInfoPopup: () => import('@app/components/TrustedSitterInfoPopup/TrustedSitterInfoPopup'),
    DebtsSuccessModal: () => import('@app/components/DebtsModal/DebtsSuccessModal'),
    PaymentPending: () => import('@app/components/PaymentPending/PaymentPending'),
    WorkTodayInfoModal: () => import('@app/components/WorkToday/WorkTodayInfo'),
    LoginModal: () => import('@app/components/Login/LoginModal'),
    AddressMap: () => import('@app/components/AddressMap/AddressMap'),
    YoutubeModal: () => import('@app/components/YoutubeModal/YoutubeModal'),
    VideoModal: () => import('@app/components/VideoModal/VideoModal'),
    PaymentErrorModal: () => import('@app/components/PaymentErrorModal/PaymentErrorModal'),
    TariffsModal: () => import('@app/components/Subscription/TariffsModal'),
    SecurityClearanceModal: () => import('@app/components/SecurityClearanceModal/SecurityClearanceModal'),
    NewDirectAnnouncement: () => import('@app/components/NewDirectAnnouncement'),
    NewSitterRequest: () => import('@app/components/NewSitterRequest/NewSitterRequest'),
    Review: () => import('@app/components/Review/Review'),
    ReviewSuccess: () => import('@app/components/ReviewSuccess/ReviewSuccess'),
    PlaygroundSuccess: () => import('@app/routes/Playground/PlaygroundSuccess'),
    QuizGameSuccessModal: () => import('@app/routes/Quiz/QuizGameSuccessModal'),
    SearchListModal: () => import('@app/routes/Search/SearchListModal'),
    SearchFilterMobile: () => import('@app/routes/Search/SearchFilterMobile'),
    AnnouncementResponseSuccessModal: () => import('@app/routes/Announcements/AnnouncementResponseSuccessModal'),
    ReviewQA: () => import('@app/components/Review/ReviewQA'),
    RejectRequest: () => import('@app/components/RejectRequest'),
    SocialNetworksInfoModal: () => import('@app/components/SocialNetworksInfoModal/SocialNetworksInfoModal'),
    ErrorModal: () => import('@app/components/ErrorModal/ErrorModal'),
    GenericSuccessMessageModal: () => import('@app/components/SuccessMessage/GenericSuccessMessageModal'),
    MegafonModal: () => import('@app/components/MegafonModal/MegafonModal'),
    CardModal: () => import('@app/components/CardModal/CardModal'),
    Confirm: () => import('@app/components/Confirm/Confirm'),
    PlaceInputModal: () => import('@app/components/PlaceInputModal/PlaceInputModal'),
    PromocodeModal: () => import('@app/components/PromocodeModal/PromocodeModal'),
    PromocodeSuccessModal: () => import('@app/routes/Settings/PromocodeSuccessModal'),
    SubscriptionModal: () => import('@app/components/Subscription/SubscriptionModal'),
    CurrencySelectModal: () => import('@app/components/CurrencySelectModal/CurrencySelectModal'),
    RequestDetailsModal: () => import('@app/components/RequestDetailsModal/RequestDetailsModal'),
    DirectAnnouncementDetailsModal: () => import('@app/components/DirectAnnouncementDetailsModal/DirectAnnouncementDetailsModal'),
    DirectAnnouncementCancelModal: () => import('@app/components/DirectAnnouncementCancelModal/DirectAnnouncementCancelModal'),
    PaymentModal: () => import('@app/components/Payment/PaymentModal'),
    PaymentElaborationModal: () => import('@app/components/PaymentElaborationModal/PaymentElaborationModal'),
    AnnouncementResponseDetailsModal: () => import('@app/components/AnnouncementResponseDetailsModal/AnnouncementResponseDetailsModal'),
    AutoAcceptAnnouncementConfirm: () => import('@app/components/AutoAcceptAnnouncementConfirm/AutoAcceptAnnouncementConfirm'),
    AnnouncementFeatureModal: () => import('@app/components/AnnouncementFeatureModal/AnnouncementFeatureModal'),
    AnnouncementDetailsModal: () => import('@app/components/AnnouncementCard/AnnouncementDetailsModal'),
    AnnouncementAutoAcceptConfirm: () => import('@app/components/AnnouncementAutoAcceptConfirm/AnnouncementAutoAcceptConfirm'),
    SittersAnnouncementCardModal: () => import('@app/components/AnnouncementCard/SittersAnnouncementCardModal'),
    ParentsAnnouncementCardModal: () => import('@app/components/AnnouncementCard/ParentsAnnouncementCardModal'),
    SchoolNannyModal: () => import('@app/routes/Static/components/SchoolNanny/SchoolNannyModal'),
  },
  routes: {
    Apps: {
      Apps: () => import(/* webpackChunkName: "routes_apps" */ '@app/routes/Apps/Apps'),
      ApkLink: () => import(/* webpackChunkName: "routes_apps" */ '@app/routes/Apps/ApkLink'),
    },
    Academy: {
      Academy: () => import(/* webpackChunkName: "routes_academy" */ '@app/routes/Academy/Academy'),
      Course: () => import(/* webpackChunkName: "routes_academy" */ '@app/routes/Academy/AcademyCourse'),
      Courses: () => import(/* webpackChunkName: "routes_academy" */ '@app/routes/Academy/AcademyCourses'),
      Cart: () => import(/* webpackChunkName: "routes_academy" */ '@app/routes/Academy/AcademyCart'),
      Lesson: () => import(/* webpackChunkName: "routes_academy" */ '@app/routes/Academy/AcademyLesson'),
      LessonJoin: () => import(/* webpackChunkName: "routes_academy" */ '@app/routes/Academy/AcademyLessonJoin'),
      Purchased: () => import(/* webpackChunkName: "routes_academy" */ '@app/routes/Academy/AcademyPurchased'),
      QuickCart: () => import(/* webpackChunkName: "routes_academy" */ '@app/routes/Academy/QuickCart'),
      MobileCart: () => import(/* webpackChunkName: "routes_academy" */ '@app/routes/Academy/MobileCart'),
      HeaderCartButton: () => import(/* webpackChunkName: "routes_academy" */ '@app/routes/Academy/HeaderCartButton'),
    },
    Announcements: {
      SitterAnnouncements: () => import(/* webpackChunkName: "routes_announcements_sitter" */ '@app/routes/Announcements/SitterAnnouncements'),
      ParentAnnouncements: () => import(/* webpackChunkName: "routes_announcements_parent" */ '@app/routes/Announcements/ParentAnnouncements'),
      Announcement: () => import(/* webpackChunkName: "routes_announcements_parent" */ '@app/routes/Announcements/Announcement'),
      NewAnnouncement: () => import(/* webpackChunkName: "routes_announcements_parent" */ '@app/routes/Announcements/NewAnnouncement'),
      EditAnnouncement: () => import(/* webpackChunkName: "routes_announcements_parent" */ '@app/routes/Announcements/EditAnnouncement'),
    },
    Messages: {
      MessagesLayout: () => import(/* webpackChunkName: "news_and_messages" */ '@app/routes/Messages/MessagesLayout'),
    },
    News: {
      News: () => import(/* webpackChunkName: "news_and_messages" */ '@app/routes/News/News'),
    },
    Search: {
      Search: () => import(/* webpackChunkName: "routes_search" */ '@app/routes/Search/Search'),
    },
    Settings: {
      RegistrationComplete: () => import(/* webpackChunkName: "routes_registration_complete" */ '@app/routes/Settings/RegistrationComplete'),
      ProfileParent: () => import(/* webpackChunkName: "routes_settings_parent" */ '@app/routes/Settings/ProfileParent'),
      ProfileSitter: () => import(/* webpackChunkName: "routes_settings_sitter" */ '@app/routes/Settings/ProfileSitter'),
      Notifications: () => import(/* webpackChunkName: "routes_settings_sitter" */ '@app/routes/NotificationSettings/NotificationSettings'),
      ProfilePhone: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/ProfilePhone'),
      Calendar: () => import(/* webpackChunkName: "routes_settings_sitter" */ '@app/routes/Settings/Calendar'),
      Courses: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/Courses'),
      History: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/History'),
      Billing: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/Billing'),
      Favorites: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/Favorites'),
      Ignored: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/Ignored'),
      Trusted: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/Trusted'),
      Promocode: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/Promocode'),
      PromocodeUnauth: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/PromocodeUnauth'),
      Referrals: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/Referrals'),
      Reviews: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/Reviews'),
      EmailConfirmation: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/EmailConfirmation'),
      EmailAuth: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/EmailAuth'),
      EmailToken: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/EmailToken'),
      TelegramAuth: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/TelegramAuth'),
      TrainingConfirmation: () => import(/* webpackChunkName: "routes_settings" */ '@app/routes/Settings/TrainingConfirmation'),
    },
    Affiliate: {
      Affiliate: () => import(/* webpackChunkName: "routes_affiliate" */ '@app/routes/Affiliate/Affiliate'),
    },
    GiftCard: {
      GiftCardOrder: () => import(/* webpackChunkName: "routes_giftcard" */ '@app/routes/GiftCard/GiftCardOrder'),
      GiftCardDetails: () => import(/* webpackChunkName: "routes_giftcard" */ '@app/routes/GiftCard/GiftCardDetails'),
    },
    Playground: {
      Playground: () => import(/* webpackChunkName: "routes_playground" */ '@app/routes/Playground/Playground'),
    },
    Static: {
      LandingSitter: () => import(/* webpackChunkName: "routes_static_sitter" */ '@app/routes/Static/components/LandingSitter/LandingSitter'),
      LandingParent: () => import(/* webpackChunkName: "routes_static_parent" */ '@app/routes/Static/components/LandingParent/LandingParent'),
      School: () => import(/* webpackChunkName: "routes_sitter_school" */ '@app/routes/Static/components/School/School'),
      SchoolNanny: () => import(/* webpackChunkName: "routes_static_sitter" */ '@app/routes/Static/components/SchoolNanny/SchoolNanny'),
      StaticLayoutWithMenu: () => import(/* webpackChunkName: "routes_static" */ '@app/routes/Static/components/StaticLayoutWithMenu/StaticLayoutWithMenu'),

      HowItWorks: () => import(/* webpackChunkName: "routes_static" */ '@app/routes/Static/components/HowItWorks/HowItWorks'),
      Contacts: () => import(/* webpackChunkName: "routes_static" */ '@app/routes/Static/components/Contacts'),
      Faq: () => import(/* webpackChunkName: "routes_static" */ '@app/routes/Static/components/Faq'),
      Insurance: () => import(/* webpackChunkName: "routes_static" */ '@app/routes/Static/components/Insurance'),
    },
    CandidateSitter: {
      CandidateSitter: () => import(/* webpackChunkName: "routes_not_approved_sitter" */ '@app/routes/CandidateSitter/CandidateSitter'),
    },
    NY2020: {
      NY2020: () => import(/* webpackChunkName: "routes_ny" */ '@app/routes/NY2020/NY2020'),
    },
    Telegram: {
      Code: () => import(/* webpackChunkName: "routes_telegram" */ '@app/routes/Telegram/TelegramCode'),
    },
    Quiz: {
      Quiz: () => import(/* webpackChunkName: "routes_quiz" */ '@app/routes/Quiz/Quiz'),
      QuizWatch: () => import(/* webpackChunkName: "routes_quiz" */ '@app/routes/Quiz/QuizWatch'),
      QuizGame: () => import(/* webpackChunkName: "routes_quiz" */ '@app/routes/Quiz/QuizGame'),
    },
    Internal: {
      BindCard: () => import(/* webpackChunkName: "routes_internal" */ '@app/routes/Internal/BindCard/BindCard'),
      TOSAccept: () => import(/* webpackChunkName: "routes_internal" */ '@app/routes/Internal/TOSAccept/TOSAccept'),
    },
  },
  utils: {
    addressTools: () => import(/* webpackChunkName: "utils_address_tools" */ '@app/utils/addressTools'),
  },
} as const
