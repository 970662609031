import { ApiNotificationSubscription } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getNotificationSubscriptionsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getNotificationSubscriptions')
  .setShape<{ data: ApiNotificationSubscription[] }>()

export const postNotificationSubscriptionsByTopicDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postNotificationSubscriptionsByTopic')
  .setShape<{ data: ApiNotificationSubscription }, { topic: string; channel: string }>()

export const deleteNotificationsSubscriptionsByTopicDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('deleteNotificationsSubscriptionsByTopic')
  .setShape<{ data: ApiNotificationSubscription }, { topic: string; channel: string }>()
