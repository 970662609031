import React, { FunctionComponent, ReactElement, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { ParameterizedContext } from 'koa'

import { FetchData } from '@app/utils/performFetchData'

import { useToggle, useToggleArray } from '@app/hooks/useToggle'

import classes from './helpers.module.scss'

const MUTATE = '@POLYGON/MUTATE'

type MUTATE_ACTION = {
  type: typeof MUTATE
  payload: (state: any) => any
}

export const isPolygonRequest = (ctx: ParameterizedContext<any>) => ctx.path.startsWith('/__polygon')

export function reducerWithMutator<R extends (state: any, action: any) => any>(reducer: R): R {
  return ((state, action) => {
    try {
      if (action.type === MUTATE) return action.payload(state)
      return reducer(state, action)
    } catch {
      return reducer(state, action)
    }
  }) as R
}

export const polygonMutate = <State extends any>(mutator: (state: State) => State): MUTATE_ACTION => ({
  type: MUTATE,
  payload: mutator,
})

export const usePolygonBoolean = (label: string, initialValue: boolean = false): [boolean, ReactNode, () => unknown] => {
  const [value, toggleValue] = useToggle(initialValue)

  return [
    value,
    // eslint-disable-next-line react/jsx-key
    <button className={classes.button} onClick={toggleValue}>
      {label} ({String(value)})
    </button>,
    toggleValue,
  ]
}

export const Line = () => <hr className={classes.line} />
export const Code: FunctionComponent<{ children?: any }> = ({ children }) => <code className={classes.code}>{JSON.stringify(children, null, 2)}</code>

interface UsePolygonListButton {
  <T extends number>(label: string, list: readonly T[], format?: (v: T) => string, deps?: any[]): [T, ReactNode]
  <T extends string>(label: string, list: readonly T[], format?: (v: T) => string, deps?: any[]): [T, ReactNode]
  <T>(label: string, list: readonly T[], format: (v: T) => string, deps?: any[]): [T, ReactNode]
}

export const usePolygonListButton: UsePolygonListButton = (
  label: string,
  list: readonly any[],
  transform?: (v: any) => any,
  deps: any[] = []
): [any, ReactNode] => {
  const [value, toggleValue] = useToggleArray(list, undefined, deps)

  // transform excluded from deps as it unlikely to by dynamic
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fvalue = useMemo(() => (transform ? transform(value) : value), [value])

  return [
    value,
    // eslint-disable-next-line react/jsx-key
    <button className={classes.button} onClick={toggleValue}>
      {label} ({fvalue})
    </button>,
  ]
}

interface UsePolygonRandomButton {
  <T extends number>(label: string): [T, ReactNode]
  <T extends number>(label: string, rand: (n: number, i: number) => T): [T, ReactNode]
  <T extends string>(label: string, rand: (n: number, i: number) => T): [T, ReactNode]
  <T>(label: string, rand: (n: number, i: number) => T, format: (val: T) => ReactNode): [T, ReactNode]
}

export const usePolygonRandom: UsePolygonRandomButton = (
  label: string,
  rand: (val: number, index: number) => any = val => val,
  format: (val: any) => ReactNode = val => val
) => {
  const [value, setValue] = useState(() => rand(Math.random(), 0))
  const index = useRef(0)

  const handleChange = useCallback(() => {
    setValue(rand(Math.random(), ++index.current))
  }, [rand])

  return [
    value,
    // eslint-disable-next-line react/jsx-key
    <button className={classes.button} onClick={handleChange}>
      {label} ({format(value)})
    </button>,
  ] satisfies [any, ReactNode]
}

export const usePolygonSlider = (label: string, min: number, max: number, initialValue: number, step?: number): [any, ReactNode] => {
  const [value, setValue] = useState(initialValue)

  const handleChange = useCallback(e => {
    setValue(e.target.value)
  }, [])

  return [
    value,
    // eslint-disable-next-line react/jsx-key
    <span>
      {label}: <input max={max} min={min} onChange={handleChange} step={step} style={{ verticalAlign: 'middle' }} type="range" value={value} /> {value}
    </span>,
  ]
}

export const createPolygonComponent = (Component: (() => ReactElement | null) & { fetchData?: FetchData }, { fetchData }: { fetchData?: FetchData } = {}) => {
  Component.fetchData = fetchData
  return Component
}
