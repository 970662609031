import { ApiPlan } from '@app/constants/ApiTypes/entities'
import { PlanModifier } from '@app/constants/ApiTypes/misc'

import { getPlansDescriptor } from '@app/store/actions/api/plans.descriptors'
import { createReducer } from '@app/store/toolkit'

export type CityIdToPlanMap = Partial<{ [key: string]: ApiPlan }>

export default createReducer<Partial<Record<PlanModifier, CityIdToPlanMap>>>({}, builder => {
  builder.addCase(getPlansDescriptor.shapes.fulfilled, (state, action) => {
    return {
      ...state,
      [action.meta.modifier]: action.payload.data.reduce<CityIdToPlanMap>((acc, plan) => {
        if (plan.attributes.city_ids.length) {
          plan.attributes.city_ids.forEach(city_id => {
            acc[city_id] = plan
          })
        }
        return acc
      }, {}),
    }
  })
})
