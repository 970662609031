import { ApiEntity } from '@app/constants/ApiTypes/entities'

import { ApiTypesMap, normalize } from './normalizer'

export const apiNormalize = (input: unknown): ApiTypesMap<ApiEntity> => {
  try {
    return normalize((input as any).payload)
  } catch {
    return {}
  }
}
