import { v4 as uuidv4 } from 'uuid'

import config from '@app/config'

import { SessionData } from '@app/constants/StoreTypes'
import { TEST_USERS } from '@app/constants/TestUsers'

import { Amplitude } from '@app/services/Amplitude'
import { AnalyticsEvent } from '@app/services/AnalyticsEvent'
import { FirebaseMessagingManager } from '@app/services/FirebaseMessagingManager'

import * as api from '@app/utils/api'
import { createCookie } from '@app/utils/cookies'
import moment from '@app/utils/moment'
import { noop } from '@app/utils/noop'
import { omit } from '@app/utils/omit'
import { clearSession, persistSession } from '@app/utils/session'
import { tryJSONparse } from '@app/utils/tryJSONparse'
import { urlEscaped } from '@app/utils/urlEscaped'

import { unwrapOr } from '@app/packages/Result/Result'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'
import { createThunk } from '@app/store/thunk'

import { updatePushNotificationsSubscribed } from './pushNotifications'
import {
  sessionDestroyActionDescriptor,
  setSessionAction,
  setShortSession,
  socialLoginActionDescriptor,
  userDestroyActionDescriptor,
} from './session.descriptors'
import { getCurrentUser } from './user'

export function setSession(headers: SessionData) {
  return createThunk(async (dispatch, getState, { cookies }) => {
    dispatch(setSessionAction(headers))

    if ((headers.persistent ?? getState().session.persistent) !== false) {
      await persistSession(cookies, omit(headers, 'persistent'))
    }
  })
}

export const sessionDestroyAction = new ApiActionBuilder(sessionDestroyActionDescriptor)
  .setInit(() => ({
    method: 'DELETE',
    endpoint: api.path('/api/v2/auth'),
    headers: api.headers(),
  }))
  .build()

/** logouts user and removes access_token (creates new one) */
export function destroySession() {
  return createThunk(async (dispatch, getState, { cookies }) => {
    const { profile, session } = getState()

    /** when true no call to api's logout will be called, thus token will be not reset */

    const user = profile.user
    const keepToken =
      session.viaURL ||
      session.supervisor?.viaURL ||
      !user ||
      ((process.env.NODE_ENV !== 'production' || config.isStaging) && !!TEST_USERS.find(u => user.id === u.id || u.token === session.access_token))

    await Promise.all([
      (async () => {
        if (keepToken) {
          dispatch<typeof sessionDestroyAction.descriptor.shapes.fulfilled>({
            type: sessionDestroyAction.descriptor.shapes.fulfilled.type,
            meta: undefined,
            payload: undefined,
          })
        } else {
          await dispatch(sessionDestroyAction())
        }
      })(),
      FirebaseMessagingManager.unsubscribe()
        .catch(noop)
        .then(() => {
          dispatch(updatePushNotificationsSubscribed(null))
        }),
    ])

    clearSession(cookies)
    if (IS_BROWSER) {
      Amplitude.shared.logout()
      window.location.replace('/')
    }
  })
}

export const socialLoginAction = new ApiActionBuilder(socialLoginActionDescriptor)
  .setInit((credentials: { provider: 'facebook' | 'vkontakte'; token: string }) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/auth/social'),
    headers: api.headers(),
    body: JSON.stringify(credentials),
    before() {
      new AnalyticsEvent('social_login', {}).sendAmplitude()
    },
    async after(resp, dispatch, _getState) {
      if (resp) {
        if (!resp.error) {
          new AnalyticsEvent('social_login_success', {}).sendAmplitude()
          await dispatch(
            setSession({
              access_token: resp.payload.data.meta.access_token,
            })
          )
        } else {
          new AnalyticsEvent('social_login_fail', {}).sendAmplitude()
        }
      }
    },
  }))
  .build()

export function loginWithSocial(credentials: { provider: 'facebook' | 'vkontakte'; token: string }) {
  return createThunk(async dispatch => {
    const response = await dispatch(socialLoginAction(credentials))

    if (response && !response.error) {
      await dispatch(
        setSession({
          access_token: response.payload.data.meta.access_token,
        })
      )
    }

    return response
  })
}

export function restoreUser(access_token: string, persistent = true) {
  return createThunk(async (dispatch, _getState) => {
    await dispatch(setSession({ access_token, persistent }))
    const response = await dispatch(getCurrentUser())
    if (response?.error) {
      await dispatch(destroySession())
    }
    return response
  })
}

export const userDestroyAction = new ApiActionBuilder(userDestroyActionDescriptor)
  .setInit(() => ({
    method: 'DELETE',
    endpoint: state => api.path(urlEscaped`/api/v2/users/${state.profile.user!.id}`)(state),
    headers: api.headers(),
  }))
  .build()

export function destroyUser() {
  return createThunk(async dispatch => {
    await dispatch(userDestroyAction())
    await dispatch(destroySession())
  })
}

const shortSessionCookie = createCookie<{ uuid: string } | null>(
  'kidsout__short_session',
  input => unwrapOr(tryJSONparse<{ uuid: string } | null>(input), null),
  value => JSON.stringify(value),
  () => ({
    expires: moment().add(1, 'year').toDate(),
    sameSite: 'lax',
    httpOnly: false,
  })
)

export function restoreSessionUUID() {
  return createThunk((dispatch, getState, { cookies }) => {
    const state = getState()
    const uuid = state.session.uuid

    if (uuid) return { uuid }

    const session = shortSessionCookie.get(cookies)
    if (session) {
      dispatch(setShortSession(session.uuid))
    } else {
      const newSession = { uuid: uuidv4() }

      shortSessionCookie.set(cookies, newSession)
      dispatch(setShortSession(newSession.uuid))
    }
  })
}
