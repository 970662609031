import { wrapError } from './wrapError'

export function asError(e: unknown) {
  if (e instanceof Error) {
    return e
  }
  if (typeof e === 'string') {
    return new Error(e)
  }
  return wrapError(new Error('Unexpected error object'), e)
}
