import { Address } from '@app/utils/address'
import { AddressManager } from '@app/utils/AddressManager'
import { arrayConverter, composeConverters, jsonParse, jsonSerialize } from '@app/utils/jsonSerialize'
import { SerializableError } from '@app/utils/SerializableError'
import { SerializedAddress } from '@app/utils/SerializedAddress'

import { StoreState } from '@app/store/store'

export class StoreSerializer {
  serialize(state: StoreState): string {
    return jsonSerialize(
      state,
      composeConverters(arrayConverter, (value, def) => {
        if (value instanceof SerializableError) return value.toJSON()
        if (value instanceof Error) return undefined
        // eslint-disable-next-line deprecation/deprecation
        if (value instanceof Address) return AddressManager.fromAddressClass(value).address
        return def()
      })
    )
  }

  deserialize(data: string): StoreState {
    return jsonParse(
      data,
      composeConverters(arrayConverter, (value, def) => {
        return SerializedAddress.fromDataToAddress(value) ?? SerializableError.fromData(value) ?? def()
      })
    )
  }
}
