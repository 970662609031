import { ApiCountry, ApiLocality, ApiPlace, ApiRegion } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getPlacesByIdDescriptor = new ApiActionBuilderDescriptor().setLabel('getPlacesById').setShape<
  {
    data: ApiPlace
    included: (ApiRegion | ApiLocality | ApiCountry)[]
  },
  { id: string }
>()

export const getPlacesByLocationDescriptor = new ApiActionBuilderDescriptor().setLabel('getPlacesByLocation').setShape<
  {
    data: ApiPlace
    included: (ApiRegion | ApiLocality | ApiCountry)[]
  },
  { latitude: number; longitude: number }
>()
