import moment, { Moment } from '@app/utils/moment'

import { ReactiveValue } from '@app/packages/reactives/ReactiveValue'

export class LazyPromise<T> {
  readonly loading = new ReactiveValue(false)
  readonly loadedAt = new ReactiveValue<Moment | null>(null)
  private value?: Promise<T>
  private abortController?: AbortController
  getter: (abortSignal: AbortSignal) => Promise<T>

  constructor(getter: (abortSignal: AbortSignal) => Promise<T>) {
    this.getter = getter
  }

  getValue() {
    if (!this.value) {
      this.abortController = new AbortController()
      this.loading.update(() => true)
      this.value = this.getter(this.abortController.signal)
        .then(v => {
          this.loadedAt.update(() => moment())
          return v
        })
        .catch(e => {
          this.unset()
          throw e
        })
        .finally(() => {
          this.loading.update(() => false)
        })
    }
    return this.value
  }

  abort() {
    this.abortController?.abort()
  }

  unset() {
    this.abortController?.abort()
    this.value = undefined
    this.loading.update(() => false)
    this.loadedAt.update(() => null)
  }
}
