import uniq from 'lodash/uniq'

import { getAnnouncementsSearchDescriptor, getAnnouncementsUpcomingDescriptor } from '@app/store/actions/api/announcements.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[]>([], builder => {
  builder.addCase(getAnnouncementsUpcomingDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data.map(x => x.id)
  })
  builder.addCase(getAnnouncementsSearchDescriptor.shapes.fulfilled, (state, action) => {
    if (action.meta.type === 'cursor') return uniq([...state, ...action.payload.data.map(x => x.id)])
    return action.payload.data.map(x => x.id)
  })
})
