import config from '@app/config'

import { wrapError } from './wrapError'

import './AmoWidgetApi.scss'

export class AmoWidgetApi {
  static available = !!config.amoWidgetId

  constructor() {}

  async load() {
    const creds = config.amoWidgetId
    if (!creds) throw new AmoWidgetApiMissingCredentialsError('Missing amo widget credentials')
    return new Promise<void>((resolve, reject) => {
      const [id, hash] = creds.split(':')
      const script = document.createElement('script')

      window.amo_social_button = {
        id,
        hash,
        locale: 'ru',
        inline: false,
        setMeta(this: { params: unknown[] }, p: unknown) {
          this.params = (this.params || []).concat([p])
        },
      }

      window.amoSocialButton =
        window.amoSocialButton ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          ;((window.amoSocialButton as any).q = (window.amoSocialButton as any).q || []).push(arguments)
        }

      script.async = true
      script.id = 'amo_social_button_script'
      script.src = 'https://gso.amocrm.ru/js/button.js'

      script.addEventListener('load', async () => {
        try {
          window.amoSocialButton('onChatReady', () => {
            resolve()
          })
        } catch (e) {
          reject(wrapError(new AmoWidgetApiLoadError("Could not load amo widget's api"), e))
        }
      })

      script.addEventListener('error', e => {
        if (typeof e === 'string') {
          reject(new AmoWidgetApiLoadError(e))
        } else {
          reject(new AmoWidgetApiLoadError('Amo widget failed to load'))
        }
      })

      document.head.appendChild(script)
    })
  }

  open() {
    window.amoSocialButton?.('runChatShow')
  }

  hide() {
    window.amoSocialButton?.('runChatHide')
  }

  async destroy() {
    window.amoSocialButton?.('runDestroy')
  }
}

export class AmoWidgetApiMissingCredentialsError extends Error {}
export class AmoWidgetApiLoadError extends Error {}
