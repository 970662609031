import * as api from '@app/utils/api'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import { getUsersMeDescriptor } from './users.descriptors'

export const getUsersMe = new ApiActionBuilder(getUsersMeDescriptor)
  .setInit((token?: string) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/users/me'),
    headers: api.headers(token ? { Token: token } : {}),
  }))
  .build()
