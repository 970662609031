import { ApiAvailableTime } from '@app/constants/ApiTypes/entities'

import { getAvailableTimesDescriptor } from '@app/store/actions/available_times.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ApiAvailableTime[] | undefined>>({}, builder => {
  builder.addCase(getAvailableTimesDescriptor.shapes.fulfilled, (state, action) => {
    const keyLabel = action.meta.type
    const key = action.meta.value
    return { ...state, [`${keyLabel}:${key}`]: action.payload.data }
  })
})
