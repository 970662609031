import { ISO8601 } from '@app/constants/Misc'

import { excludeAbortError } from '@app/errors/AbortError'

import moment from '@app/utils/moment'

import { getUsersMeDescriptor } from '@app/store/actions/api/users.descriptors'
import { putUsersPerksDescriptor } from '@app/store/actions/perks.descriptors'
import {
  editProfile,
  phoneChangeConfirmDescriptor,
  postAuthPhoneVerifyDescriptor,
  postEmailCodeVerificationDescriptor,
  postEmailTokenVerificationDescriptor,
  postTOSAcceptanceDescriptor,
  updateProfileActionDescriptor,
} from '@app/store/actions/profile.descriptors'
import { socialLoginActionDescriptor } from '@app/store/actions/session.descriptors'
import { postAuthTelegramDescriptor } from '@app/store/actions/telegram.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  loading: boolean
  loadedAt: ISO8601 | null
  error: Error | null
  perksLoading: boolean
  perksError: Error | null
}>(
  {
    loading: false,
    loadedAt: null,
    error: null,
    perksLoading: false,
    perksError: null,
  },
  builder => {
    builder.addCase(updateProfileActionDescriptor.shapes.pending, (state, _action) => {
      return { ...state, error: null, loading: true }
    })

    builder.addCases(
      [
        getUsersMeDescriptor.shapes.fulfilled,
        socialLoginActionDescriptor.shapes.fulfilled,
        postAuthTelegramDescriptor.shapes.fulfilled,
        postTOSAcceptanceDescriptor.shapes.fulfilled,
        postAuthPhoneVerifyDescriptor.shapes.fulfilled,
        phoneChangeConfirmDescriptor.shapes.fulfilled,
        editProfile.shape,
        updateProfileActionDescriptor.shapes.fulfilled,
        postEmailCodeVerificationDescriptor.shapes.fulfilled,
        postEmailTokenVerificationDescriptor.shapes.fulfilled,
      ],
      (state, _action) => {
        return { ...state, loading: false, loadedAt: moment().format(), error: null }
      }
    )

    builder.addCase(updateProfileActionDescriptor.shapes.rejected, (state, action) => {
      return { ...state, loading: false, error: excludeAbortError(action.payload) }
    })

    builder.addCase(putUsersPerksDescriptor.shapes.pending, (state, _action) => {
      return { ...state, perksError: null, perksLoading: true }
    })

    builder.addCase(putUsersPerksDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, perksError: null, perksLoading: false }
    })

    builder.addCase(putUsersPerksDescriptor.shapes.rejected, (state, action) => {
      return { ...state, perksError: excludeAbortError(action.payload), perksLoading: false }
    })
  }
)
