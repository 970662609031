export class RAIIGuard {
  constructor(private readonly onChange: (val: boolean) => void) {
    this.onChange(true)
  }

  guard() {}

  [Symbol.dispose]() {
    this.onChange(false)
  }
}
