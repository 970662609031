import { Address } from './address'
import { AddressManager } from './AddressManager'

export class SerializedAddress {
  static readonly key = 'address:9FCF959D-4A7C-40EC-A6A1-9FA577FBEBD0'

  static fromDataToAddress(data: any) {
    try {
      if (data.key !== SerializedAddress.key) return undefined
      // eslint-disable-next-line deprecation/deprecation
      return AddressManager.fromAddressClass(Address.fromJSON(data.data)).address
    } catch {
      return undefined
    }
  }
}
