import { ApiCountry } from '@app/constants/ApiTypes/entities'

import { apiNormalize } from '@app/utils/apiNormalizer'

import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiCountry
}>({}, builder => {
  builder.defaultHandler = (state, action) => {
    const { countries } = apiNormalize(action)
    if (!countries) return state
    return { ...state, ...countries }
  }
})
