import isNil from 'lodash/isNil'
import pickBy from 'lodash/pickBy'

import { Bounds } from './Bounds'
import { Point } from './Point'

export type Address = {
  label?: string
  kind?: string
  location: Point
  bounds?: Bounds
}

export function createAddress(address: Address): Address {
  return pickBy(address, v => !isNil(v)) as Address
}
